<template>
  <div class="sidebar-menu-container mobile-font" id="sidebar-menu-container">
    <div class="sidebar-menu-push">
      <div class="sidebar-menu-overlay"></div>
      <div class="sidebar-menu-inner">
        <NavBar></NavBar>

        <div class="banner-wrapper">
          <div class="banner-background">
            <carousel :per-page="1" :mouse-drag="false" :paginationPosition="'bottom-overlay'">
              <slide>
                <div class="banner-bg-1">
                </div>
              </slide>
              <slide>
                <div class="banner-bg-2">
                </div>
              </slide>
              <slide>
                <div class="banner-bg-3">
                </div>
              </slide>
              <slide>
                <div class="banner-bg-4">
                </div>
              </slide>
              <slide>
                <div class="banner-bg-5">
                </div>
              </slide>
              <slide>
                <div class="banner-bg-6">
                </div>
              </slide>
            </carousel>
          </div>
          <div class="banner-text-section">
            <div>
              <h1 class="mbft-20">Integrity Homes Limited is the best solution for architecture and building</h1>
            </div>
            <!--            <div class="banner-small-text">-->
            <!--              Huo construction is the best solution for architecture and building-->
            <!--            </div>-->
            <button @click="learnMore" class="banner-button mbft-12">LEARN MORE</button>
          </div>

        </div>
        <div id="aq-template-wrapper-322" class="aq-template-wrapper aq_row"><!-- Section Start -->
          <div id class="clearfix standard" style="background-color:#e6772e;   padding-bottom:20px; padding-top:20px;">
            <div class="container">
              <div class="row">
                <div id="aq-block-322-2" class="aq-block aq-block-ptf_cta_block aq_span12 aq-first clearfix">
                  <div class="call-to-action">
                    <div class="cta-inner clearfix text-center-sm">
                      <div class="cta-text pa-sm-0">
                        <h5 class="cta-title">Master builder membership in NZIA</h5>
                      </div>


                      <div class="cta-button">
                        <a target="_self" href="#" class="btn btn-bordered">Know more?</a>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div><!-- Section End -->
        </div>


        <div id="aq-template-wrapper-114" class="aq-template-wrapper aq_row"><!-- Section Start -->
          <div id="services" class="clearfix standard py-60 py-sm-20" style="background-color:#ffffff;">
            <div class="container">
              <div class="row">
                <div class="services-wrapper">
                  <div class="text-center service-post col-md-4"><span
                      class="service-icon icon-equalizer ma-sm-0"></span>
                    <h5 class="service-title">Design and build</h5>
                    <p>one-stop service, subdivide project</p>
                    <div class="spacer"></div>
                  </div>
                  <div class="text-center service-post col-md-4"><span class="service-icon icon-picture ma-sm-0"></span>
                    <h5 class="service-title">Commercial</h5>
                    <p>Workshop and Warehouse</p>
                    <div class="spacer"></div>
                  </div>
                  <div class="text-center service-post col-md-4"><span class="service-icon icon-layers ma-sm-0"></span>
                    <h5 class="service-title">Building Type</h5>
                    <p>Residential Group housing, Terrace housing, Farmhouses and homesteads</p>
                    <div class="spacer"></div>
                  </div>
                  <!--                  <div class="text-center service-post col-md-3"><span class="service-icon fa fa-gear"></span>-->
                  <!--                    <h5 class="service-title">Project in process</h5>-->
                  <!--                    <p>Apartment, Retirement Village&amp;amp;amp;amp;Kindergarten </p>-->
                  <!--                    <div class="spacer"></div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div><!-- Section End --><!-- Section Start -->
          <div id="recent-projects" class="clearfix full-width py-60  py-sm-20" style="background-color:#f2f2f2;">
            <div class="clearfix section-header">
              <div class="col-sm-12 text-center">
                <h3 style="color:#3e3e3e;" class="mbft-20 pb-sm-20 ma-sm-0">Recent Projects</h3>
              </div>
            </div>

            <div class="projects-holder clearfix">
              <article class="project-post col-md-4" v-for="(projs, i) in recentProjs" :key="i">
                <a href="#" class="project-link">
                  <div class="project-overlay"></div>
                  <div class="image-placeholder">
                    <img width="840" height="525" :src="projs.img"
                      class="attachment-ptf-project-thumb size-ptf-project-thumb wp-post-image" alt loading="lazy">
                  </div>
                </a>
              </article>

            </div><!-- /.projects-holder -->
          </div><!-- Section End --><!-- Section Start -->
          <div id="why-choose-huo" class="clearfix standard py-60 py-sm-20" style="background-color:#ffffff;">
            <div class="container">
              <div class="row">
                <div class="clearfix section-header">
                  <div class="col-sm-12 text-center">
                    <h3 style="color:#3e3e3e;" class="mbft-20 pb-sm-20 ma-sm-0">Why Choose Integrity Homes Limited</h3>


                  </div>
                </div>

                <div id="aq-block-114-10"
                  class="aq-block aq-block-ptf_text_image_block aq_span6 aq-first clearfix max-width-sm px-sm-2">
                  <div class="row">
                    <div class="col-md-8  col-sm-12">
                      <p> Integrity Homes has New Zealand Building Qualification (LBP)
                        and Site 2 management qualifications. At the same time<br>
                        Provide Vero Liability Insurance Ltd with comprehensive insurance, such as Public Liability,
                        Professional Indemnity, Statutory Liability, and Employer Liability. The Integrity Homes team
                        is
                        a team with rich experience in building and efficient management. It also has a good reputation
                        and outstanding performance in the mainstream construction industry. It has won the annual award
                        of New Zealand Master Builder, which is the recognition of our company's strength. There is a
                        high
                        evaluation in the local mainstream society and the Chinese community.</p>
                      <div class="spacer"></div>
                    </div>
                  </div>
                </div>
                <div id="aq-block-114-11" class="aq-block aq-block-ptf_skillbar_block aq_span6 clearfix
                 max-width-sm ma-sm-0 pa-sm-2">
                  <div class="progress-wrapper">
                    <div class="progress-text">
                      <span class="left">DESIGN AND BUILD ONE -STOP SERVICE</span>
                      <span class="right">100%</span>
                    </div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style="width:100%"></div>
                    </div>
                    <div class="progress-text">
                      <span class="left">Residential Group housing, Terrace housing, Farmhouses and homesteads</span>
                      <span class="right">100%</span>
                    </div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style="width:100%"></div>
                    </div>
                    <div class="progress-text">
                      <span class="left">LIGHT COMMERCIAL PROJECT</span>
                      <span class="right">100%</span>
                    </div>
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" style="width:100%"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- Section End --><!-- Section Start -->
          <div id="testimonials" class="clearfix py-60 py-sm-20" style="background-color:#e0e0e0;">
            <!--            <div data-stellar-background-ratio="0.5" class="parallax-image"></div>-->
            <div>
              <div class="container">
                <div class="row">
                  <div class="clearfix section-header">
                    <div class="col-sm-12 text-center">
                      <h3 class="mbft-20 ma-sm-0 pb-sm-20">Rewards</h3>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="client-quote">
                      <div class="image-placeholder" v-viewer>
                        <img width="200" src="images/home/reward/reward-1.jpg" class="single-image">
                      </div>
                      <p class="description">Reward One</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="client-quote" v-viewer>
                      <img width="200" src="images/home/reward/reward-2.jpg" class="single-image" alt loading="lazy">
                      <p class="description">Reward Two</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="client-quote" v-viewer>
                      <img width="200" src="images/home/reward/reward-3.jpg" class="single-image" alt loading="lazy">
                      <p class="description">Reward Three</p>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="client-quote" v-viewer>
                      <img width="200" src="images/home/reward/reward-4.jpg" class="single-image" alt loading="lazy">
                      <p class="description">Reward Four</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- Section End -->
          <section>
            <!-- <div id="testimonials" class="clearfix py-60 py-sm-20" style="background-color:#e0e0e0;"> -->
            <!--            <div data-stellar-background-ratio="0.5" class="parallax-image"></div>-->
              <div>
                <div class="container">
                  <div class="row">
                    <div class="clearfix section-header">
                      <div class="col-sm-12 text-center">
                        <h3 class="mbft-20 ma-sm-0 pb-sm-20">Sponser</h3>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="sponser-quote">
                        <div class="image-placeholder" v-viewer>
                          <img width="262" src="images/home/sponser/1.jpg" class="single-image">
                        </div>
                        <p class="description">Integrity Homes gives back to the community and sponsors the 2022 year Friendship Cup Auckland
and won the championship in Group A</p>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="sponser-quote" v-viewer>
                        <img width="262" src="images/home/sponser/2.jpg" class="single-image" alt loading="lazy">
                        <p class="description">Integrity Homes gives back to the community and sponsors the 2023 year Friendship Cup Auckland
and won the best team championship</p>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="sponser-quote">
                        <div class="image-placeholder" v-viewer>
                          <img width="262" src="images/home/testimonial/reward_1.jpg" class="single-image">
                        </div>
                        <p class="description"> Integrity Homes received customers’ praise and affirmation. Mr. He personally made the pennants and brought exquisite gifts to our team. Thanks to our company for solving the very difficult problems in the investment project for him.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          </section>
        </div>
          <FooterBar></FooterBar>


          <a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>

        </div>

      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import { Carousel, Slide } from 'vue-carousel';
import Remote, {api} from "@/remote/Remote";


export default {
  name: 'Home',
  components: {
    NavBar,
    FooterBar,
    Carousel,
    Slide
  },

  data() {
    return {
      recentProjs: [
        {
          img: 'images/home/recent-proj/1.jpg'
        },
        {
          img: 'images/home/recent-proj/2.jpg'
        },
        {
          img: 'images/home/recent-proj/3.jpg'
        },
        {
          img: 'images/home/recent-proj/4.jpg'
        },
        {
          img: 'images/home/recent-proj/5.jpg'
        },
        {
          img: 'images/home/recent-proj/6.jpg'
        }
      ],
      news: []
    }
  },
  mounted() {

    let script = document.createElement('script')
    script.setAttribute('src', 'js/wpls-public.js')
    document.head.appendChild(script)

    Remote.getNews().then(data => {
      this.news = data.map(news => {
        console.log('news', news)
        news.Cover.fullUrl = api + news.Cover.url
        return news
      })

      console.log('this news', this.news)
    })

  },
  methods: {
    learnMore() {
      this.$router.push({ path: 'gallery' })
    }
  }
}
</script>
<style lang="scss">

@use '../style/layout';

  #wpls-logo-showcase-slider-1 .wpls-fix-box,
  #wpls-logo-showcase-slider-1 .wpls-fix-box img.wp-post-image {
    max-height: 250px;
  }

  .banner-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    
    @media screen and (max-width: layout.$mobile-width) {
      height: 60vh;
    }
  
  }

  .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* background: url('/images/home/banner/banner.jpg');
    background-size: cover;
    background-position: center center; */
  }

  .banner-bg-common {
    width: 100%;
    height: 80vh;

    @media screen and (max-width: layout.$mobile-width) {
      height: 60vh;
    }
  }

  .banner-bg-1 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/banner.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-bg-2 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/2.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-bg-3 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/3.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-bg-4 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/4.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-bg-5 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/5.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-bg-6 {
    @extend .banner-bg-common;
    background: url('/images/home/banner/6.jpg');
    background-size: cover;
    background-position: center center;
  }

  .banner-text-section {
    color: white;
    width: 1100px;
    padding: 20px 40px;
    background: #65646491;
    z-index: 1;

  }

  .banner-text-section h1 {
    color: white;
  }

  .banner-small-text {
    margin-top: 40px;
  }

  .banner-button {
    color: black;
    border: 1px solid white;
    margin-top: 10px;
    background: white;
    padding: 5px 30px;
    font-size: 14px;
    text-transform: capitalize;
  }

  .intro-text {
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

@media screen and (max-width: layout.$mobile-width) {
  article.project-post .image-placeholder img {
    width: 50%;
    height: auto;
    margin: 0 auto;
  }
}


</style>
