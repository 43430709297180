<template>
  <header class="site-header mobile-font">

    <div id="main-header" class="main-header header-sticky" data-sticky="on">
      <div class="inner-header clearfix">
        <div class="logo pa-sm-2">
          <a href="/" title="HUOCONSTRUCTION" rel="home">
            <img class="logo-holder" src="images/logo/logo.jpg" alt="HUOCONSTRUCTION">
          </a>
        </div>
        <div class="header-right-toggle pull-right visible-sm visible-xs pa-sm-2" @click="toogleMobileNav">
          <a href="javascript:void(0)" class="side-menu-button"><i class="fa fa-bars"></i></a>
        </div>

        <nav class="main-navigation pull-right hidden-xs hidden-sm">
          <ul id="menu-main-navigation" class="menu">
            <li :class="{ 'current-menu-item': $route.path === '/' }" id="menu-item-244" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home  page_item page-item-112 menu-item-244">
<!--              <a href="index.html" aria-current="page">Home</a>-->
              <router-link to="/">Home</router-link>
            </li>
            <li :class="{ 'current-menu-item': $route.path === '/gallery' }" id="menu-item-246" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-246">
<!--              <a href="index_2.html">Project Gallery</a>-->
              <router-link to="/gallery">Project Gallery</router-link>
            </li>
            <li :class="{ 'current-menu-item': $route.path === '/contact' }" id="menu-item-208" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-208">
<!--              <a href="index_4.html">Contact</a>-->
              <router-link to="/contact">Contact</router-link>
            </li>
            <li :class="{ 'current-menu-item': $route.path === '/about' }" id="menu-item-385" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-385">
<!--              <a href="index_15.html">About</a>-->
              <router-link to="/about">About</router-link>
            </li>
<!--            <li id="menu-item-304" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-304">-->
<!--              <a href="cn.html">中文</a>-->
<!--              <router-link to="/"></router-link>-->
<!--            </li>-->
          </ul>
        </nav>
      </div>
    </div>

    <nav class="sidebar-menu slide-from-left" v-bind:class="{'open': isMobileNavShow}">
      <div class="nano">
        <div class="content">
          <nav class="responsive-menu">
            <ul id="menu-main-navigation-1" class>
              <router-link to="/">Home</router-link>
              <router-link to="/gallery">Project Gallery</router-link>
              <router-link to="/contact">Contact</router-link>
              <router-link to="/about">About</router-link>
            </ul>
          </nav>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
name: "NavBar",
  
data() {
  return {
    isMobileNavShow: false
  }
},

methods: {
  toogleMobileNav() {
    this.isMobileNavShow = !this.isMobileNavShow 
  }
}
}
</script>

<style scoped lang="scss">
html {
  font-size: 1rem;
}

.sidebar-menu {
  width: 0
}

.open {
    visibility: visible;
    z-index: 99999;
    width: 250px;
  }

// @include media-breakpoint-up(sm) {
//   html {
//     font-size: 1.2rem;
//   }
// }

// @include media-breakpoint-up(md) {
//   html {
//     font-size: 1.4rem;
//   }
// }

// @include media-breakpoint-up(lg) {
//   html {
//     font-size: 1.6rem;
//   }
// }
</style>
