<template>
<div>
    <div class="sidebar-menu-container mobile-font" id="sidebar-menu-container">
        <div class="sidebar-menu-push">
            <div class="sidebar-menu-overlay"></div>
            <div class="sidebar-menu-inner">
            <NavBar></NavBar>
            <div v-if="news">
                <section>
                    <div class="title-container">
                      <h3>{{news.Title}}</h3>
                    </div>

                    <div class="content-container">
                        <div class="image-container">
                          <img :src="`${coverImageURL}`" alt="New Project">
                        </div>

                        <pre>{{news.Content}}</pre>
                    </div>                        
                </section>
            </div>
            <FooterBar></FooterBar>

            <a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>

            </div>
        </div>
    </div>

</div>    
</template>

<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import Remote from "@/remote/Remote";
import { api } from "@/remote/Remote";


export default {
    components: {
        NavBar,
        FooterBar
    },

    data() {
        return {
            news: null,
          coverImageURL: '',
        }
    },

    mounted() {
       console.log(this.$route.query)
        if (this.$route.query && this.$route.query.id) {
            this.getNews(this.$route.query.id)

        }

    },
    methods: {
        getNews(id) {
            Remote.getNewsById(id).then(data => {
              console.log('data', data)
              this.coverImageURL = api + data.Cover.url
              this.news = data
            })
        }
    }
}
</script>

<style lang="scss">
@use '../style/layout';

.title-container {
  text-align: center;
  max-width: 1170px;
  margin: 0 auto;

  @media screen and (max-width: layout.$large-width) {
    width: 100%;
    padding: 0 60px;
  }

  @media screen and (max-width: layout.$mobile-width) {
    width: 100%;
    padding: 0 20px;
    margin: 20px auto;
  }
}

.content-container {
    width: 1170px;
    max-width: 1170px;
    margin: 60px auto;
    box-sizing: border-box;

    @media screen and (max-width: layout.$large-width) {
      width: 100%;
      padding: 0 60px;
    }

    @media screen and (max-width: layout.$mobile-width) {
      width: 100%;
      padding: 0 20px;
      margin: 20px auto;
    }

    pre {
      background: white;
      border: none;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      @media screen and (max-width: layout.$mobile-width) {
        font-size: 12px;
      }
    }


}

.image-container {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;

    img {
      max-width: 100%;
    }
}
</style>