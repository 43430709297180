import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCarousel from 'vue-carousel';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import './style/global.scss';


Vue.use(Viewer, {
  defaultOptions: {
    toolbar: 0
  }
})
Vue.use(VueCarousel);
Vue.config.productionTip = false
// Vue.prototype.$hostUrl = 'http://54.79.142.4';
// Vue.prototype.$hostUrl = 'http://localhost:1337';
Vue.prototype.$hostUrl = '';

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
