<template>

    <div class="sidebar-menu-container mobile-font" id="sidebar-menu-container">
      <div class="sidebar-menu-push">
        <div class="sidebar-menu-overlay"></div>
        <div class="sidebar-menu-inner">
          <NavBar></NavBar>
          <section class="project-single">
            <div class="container">
            <div class="row">
              <div class="col-md-5 project-details">
                <h4 class="project-title">{{ name }}</h4>
                <div class="project-description">
                  <p>{{ description }}</p>
                </div>
                <div class="project-services">
                  <div class="project-detail-item">
                    <div class="left">
                      <i class="fa fa-user"></i><strong>Client:</strong>
                    </div>
                    <div class="right">
                      {{ client }}
                    </div>
                  </div>
                  <div class="project-detail-item">
                    <div class="left">
                      <i class="fa fa-calendar"></i><strong>Date:</strong>
                    </div>
                    <div class="right">
                      {{date}}
                    </div>
                  </div>
                  <div class="project-detail-item">
                    <div class="left">
                      <i class="fa fa-map-marker"></i><strong>Location:</strong>
                    </div>
                    <div class="right">
                      {{location}}
                    </div>
                  </div>
                  <div class="project-detail-item">
                    <div class="left">
                      <i class="fa fa-folder-open-o"></i><strong>Category:</strong>
                    </div>
                    <div class="right">
                      {{ category }}
                    </div>
                  </div>
                  <div class="project-detail-item">
                    <div class="left">
                      <i class="fa fa-share-alt"></i><strong>Share on:</strong>
                    </div>
                    <div class="right">
<!--                      <a-->
<!--                          onclick="window.open('https://www.facebook.com/sharer.php?s=100&amp;p[url]=http%3A%2F%2Fwww.huoconstruction.co.nz%2F%3Fproject%3Dnew-project','sharer', 'toolbar=0,status=0,width=620,height=280');"-->
<!--                          title="Share on Facebook" href="javascript:;">Facebook</a>-->
<!--                      <a-->
<!--                          onclick="popUp=window.open('http://twitter.com/home?status=New+Project http%3A%2F%2Fwww.huoconstruction.co.nz%2F%3Fproject%3Dnew-project','sharer','scrollbars=yes,width=800,height=400');popUp.focus();return false;"-->
<!--                          title="Share on Twitter" href="javascript:;">Twitter</a>-->
<!--                      <a title="Share on Google+" href="javascript:;"-->
<!--                         onclick="popUp=window.open('https://plus.google.com/share?url=http%3A%2F%2Fwww.huoconstruction.co.nz%2F%3Fproject%3Dnew-project','sharer','scrollbars=yes,width=800,height=400');popUp.focus();return false;">Google-->
<!--                        Plus</a>-->
                    </div>
                  </div>


                </div>
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="project_gallery">
                    <div class="col-sm-12">
                      <div class="project-image-placeholder">
                        <a data-effect="mfp-zoom-in"
                           :href="`${$hostUrl}${images[0].attributes.url}`">
                          <img :src="`${$hostUrl}${images[0].attributes.url}`"
                               alt="New Project">
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-6" v-for="image in images">
                      <div class="project-image-placeholder">
                        <a data-effect="mfp-zoom-in"
                           :href="`${$hostUrl}${image.attributes.url}`">
                          <img :src="`${$hostUrl}${image.attributes.url}`" alt="New Project">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="project-nav">
                  <div class="project-next">
                    <a class="btn btn-default" href="index_16.html"
                       title="Unit 29-34, 101 Harbour Drive, Matarangi, Coromandel">Next Project<i class="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>


          </div>
          </section>
        </div>

      </div>
      <FooterBar></FooterBar>

      <a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>

<!--    </div>-->
    </div>


</template>
<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import Remote from "@/remote/Remote";
import { blogs } from "@/remote/blogData";

export default {
  name: 'ProjectS',
  components: {
    NavBar,
    FooterBar
  },
  data() {
    return {
      images: [],
      name: '',
      description: '',
      location: '',
      client: '',
      category: ''

    }
  },
  mounted() {
    console.log('routes', this.$route.query.id)


    Remote.getProjectById( this.$route.query.id).then(data => {
      console.log('data', data)
      this.images = data.data.attributes.Images.data;
      this.name = data.data.attributes.Project_Name;
      this.description = data.data.attributes.Description;
      this.date = data.data.attributes.Date;
      this.location = data.data.attributes.Location;
      this.client = data.data.attributes.Client;
      this.category = data.data.attributes.Category;
      console.log('this.images', this.images)
    })
  },
  methods: {
    loadLocalData() {
      // let id = this.$route.query.id;
      // let filteredDatas = blogs.filter(blog => {
      //   console.log('blog.id === this.$route.query.id', blog.id === Number(id))
      //   return blog.id === Number(id)
      // })
      //
      // let data = filteredDatas[0]
      //
      // this.images = data.Images;
      // this.name = data.Project_Name;
      // this.description = data.Description;
      // this.date = data.Date;
      // this.location = data.Location;
      // this.client = data.Client;
      // this.category = data.Category;
      //
      // console.log('data', data)
    }
  }
}

</script>
<style scoped lang="scss">


</style>
