import axios from 'axios'

export const api = '/api'
// export const api = 'http://localhost:1337/api'

function getProjects() {
    return axios.get(api + '/blogs?sort=id:desc&populate=*')
        .then(res => {
            return res.data
        })
}

function getProjectById(id) {
    return axios.get(api + `/blogs/${id}?populate=*`)
        .then(res => {
            return res.data
        })
}

function getNews() {
    return axios.get(api + `/news-collections`)
        .then(res => {
            return res.data
        })
}


function getNewsById(id) {
    return axios.get(api + `/news-collections/${id}`)
        .then(res => {
            return res.data
        })
}

function sendEmail(data) {
    return axios.post(api + '/send-email', data)
        .then(res => {
            return res.data
        })

}


export default {
    getProjects,
    getProjectById,
    getNews,
    getNewsById,
    sendEmail
}