<template>
  <div>
    <div class="sidebar-menu-container mobile-font" id="sidebar-menu-container">
      <div class="sidebar-menu-push">
        <div class="sidebar-menu-overlay"></div>
        <div class="sidebar-menu-inner">
          <NavBar></NavBar>
          <section class="section-header">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h3 class="mbft-20">Project Gallery</h3>
                  <p>Find your love architecture</p>
                </div>
              </div>
            </div>
          </section>

          <div class="projects">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <ul id="projects-filter" style="padding-top: 0">
                    <li><a href="#" data-filter="*" class="active" @click="filterByCategory()">Show All</a></li>
                    <li><a href="#" data-filter=".apartment" @click="filterByCategory('Apartment')">Apartment</a></li>
                    <li><a href="#" data-filter=".building" @click="filterByCategory('Building')">Building</a></li>
                    <li><a href="#" data-filter=".commercial" @click="filterByCategory('Commercial')">Commercial</a></li>
                    <!-- <li><a href="#" data-filter=".luxury-villa" @click="filterByCategory('LuxuryVilla')">luxury villa</a></li> -->
                    <li><a href="#" data-filter=".processing" @click="filterByCategory('Processing')">Processing</a></li>
                    <li><a href="#" data-filter=".retirement-village" @click="filterByCategory('RetirementVillage')">Retirement Village</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="projects-grid">
                <div class="projects-grid-row" id="projects-grid">

                  <div class="col-md-4 col-sm-6 project-item apartment " v-for="(project, i) in projects" :key="i">
                    <article class="project-post">
                      <router-link :to="`/project?id=${project.id}`" class="project-link">
                        <div class="project-overlay"></div>
                        <div class="project-hover">
                          <h5 class="project-title">{{project.name}}</h5>
                          <p class="project-category">{{project.category}} </p>
                        </div>
                        <div class="image-placeholder">
                          <img width="400" height="400"
                               :src="project.image"
                               :style="{'object-fit': 'cover'}"
                               class="attachment-ptf-project-thumb size-ptf-project-thumb wp-post-image" alt
                               loading="lazy"></div>
                      </router-link>
                    </article>
                  </div>
                </div>
              </div>
            </div>

            <!-- <nav class="container page-navigation">
              <div class="spacer-30 clearfix"></div>
              <div class="row">
                <div class="col-sm-12 text-center">
                  <span class="page-numbers current">1</span>
                  <a class="page-numbers" href="index_22.html">2</a>
                  <a class="next page-numbers" href="index_22.html"><i class="fa fa-angle-right"></i></a></div>
              </div>
            </nav> -->


            <div class="spacer-30 clearfix"></div>
          </div>
          <FooterBar></FooterBar>

          <a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>

        </div>

      </div>
      <nav class="sidebar-menu slide-from-left">
        <div class="nano">
          <div class="content">
            <nav class="responsive-menu">
              <ul id="menu-main-navigation-1" class>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-244"><a
                  href="index.html">Home</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-301"><a
                  href="index_13.html">Services</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-246"><a
                  href="index_2.html">Project Gallery</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"><a
                  href="index_4.html">Contact</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-385"><a
                  href="index_15.html">About</a></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-304"><a
                  href="cn.html">中文</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import Remote from "@/remote/Remote";
import { blogs } from "@/remote/blogData";
import { api } from "@/remote/Remote";

export default {
  name: 'Gallery',
  components: {
    NavBar,
    FooterBar
  },
  data() {
    return {
      projects: [],
      remoteProjects: [],
    }
  },
  mounted() {
    console.log(blogs)
    // this.loadLocalCategory();
    this.loadRemote()

   
  },

  methods: {
    loadRemote() {
      Remote.getProjects().then(data => {
        console.log('data getProjects', data)
        this.remoteProjects = data.data.map(project => {
          console.log('project', project.attributes.Project_name)
          console.log('url', project.attributes.Images.data[0].attributes.url)
          return {
            name: project.attributes.Project_name,
            category: project.attributes.Category,
            id: project.id,
            image: project.attributes.Images.data[0] ?  `${this.$hostUrl}${project.attributes.Images.data[0].attributes.url}` : ''
          }
        })
        this.projects = this.remoteProjects.slice();
        console.log('this.projects', this.projects)
      })
    },

    // loadLocalCategory() {
    //   this.remoteProjects = blogs.map(project => {
    //     return {
    //       name: project.Project_Name,
    //       category: project.Category,
    //       id: project.id,
    //       image: project.Images[0] ?  `${project.Images[0].url}` : ''
    //     }
    //   })
    //   this.projects = this.remoteProjects.slice();
    //   console.log('this.projects', this.projects)
    // },

    filterByCategory(category) {
      console.log('clicked', category)
      this.projects = this.remoteProjects.slice()
      if (!category) {
        return
      }

      this.projects = this.projects.filter(project => {
        return project.category === category;
      })
    }
  }
}
</script>
<style lang="scss">

@use '../style/layout';

.projects-grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
}
  .project-item {
    height: 400px;
    width: 400px;

    @media screen and (max-width: layout.$mobile-width) {
      height: 200px;
    }
  }

#projects-filter li {
  @media screen and (max-width: layout.$mobile-width)  {
    margin: 5px 0;
    width: 150px;
  }
}
</style>
