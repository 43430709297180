<template>
  <footer>
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            2024 Integrity Homes
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
name: "FooterBar"
}
</script>

<style scoped lang="scss">
footer .footer-widgets {
  @media screen and (max-width: 600px) {
    padding-top: 20px;
  }
}

footer .footer-widgets .widget {
  @media screen and (max-width: 600px) {
    margin: 0
  }
}
</style>
