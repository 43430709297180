<template>
  <div>
    <div id="sidebar-menu-container" class="sidebar-menu-container mobile-font">
      <div class="sidebar-menu-push">
        <div class="sidebar-menu-overlay"></div>
        <div class="sidebar-menu-inner">
          <NavBar></NavBar>

          <div class="elementor elementor-60 elementor-bc-flex-widget" data-elementor-id="60"
               data-elementor-settings="[]" data-elementor-type="wp-post">
            <div class="elementor-inner">
              <div class="elementor-section-wrap">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-0fbb641 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="0fbb641">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4ef7a46"
                          data-element_type="column" data-id="4ef7a46">
                        <div class="elementor-column-wrap">
                          <div class="elementor-widget-wrap">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-41ed1ee elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="41ed1ee">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-b9b34b7"
                          data-element_type="column" data-id="b9b34b7">
                        <div class="elementor-column-wrap">
                          <div class="elementor-widget-wrap">
                          </div>
                        </div>
                      </div>
                      <div
                          class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-2e98c58"
                          data-element_type="column" data-id="2e98c58">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-cb3b85b elementor-widget elementor-widget-heading"
                                data-element_type="widget" data-id="cb3b85b" data-widget_type="heading.default">
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default mbft-20">Our Services</h2></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                          class="elementor-column elementor-col-16 elementor-top-column elementor-element elementor-element-210459e"
                          data-element_type="column" data-id="210459e">
                        <div class="elementor-column-wrap">
                          <div class="elementor-widget-wrap">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-96ba59b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="96ba59b">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74829e7"
                          data-element_type="column" data-id="74829e7">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-b17458e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-element_type="section" data-id="b17458e">
                              <div class="elementor-container elementor-column-gap-default">
                                <div class="elementor-row">
                                  <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ccfc6c5"
                                      data-element_type="column" data-id="ccfc6c5">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                      <div class="elementor-widget-wrap">
                                        <div
                                            class="elementor-element elementor-element-e1f9642 elementor-widget elementor-widget-image"
                                            data-element_type="widget" data-id="e1f9642"
                                            data-widget_type="image.default">
                                          <div class="elementor-widget-container">
                                            <div class="elementor-image">
                                              <img alt class="attachment-ptf-project-thumb size-ptf-project-thumb"
                                                   height="525"
                                                   loading="lazy"
                                                   src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002223106-840x525.jpg"
                                                   width="840"></div>
                                          </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-615be28 elementor-widget elementor-widget-heading"
                                            data-element_type="widget" data-id="615be28"
                                            data-widget_type="heading.default">
                                          <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default mbft-20">Foundation</h2>
                                          </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-19b5cd2 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                            data-element_type="widget" data-id="19b5cd2"
                                            data-widget_type="icon-list.default">
                                          <div class="elementor-widget-container">
                                             <ul class="elementor-icon-list-items">
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Typical footing and stem wall construction</span>
                                              </li>
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>

                                                <span class="elementor-icon-list-text">Slab on grade with a turned-down edge</span>
                                              </li>
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Permanent wood foundations</span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                      class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2b9d95d"
                                      data-element_type="column" data-id="2b9d95d">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                      <div class="elementor-widget-wrap">
                                        <div
                                            class="elementor-element elementor-element-abbedf9 elementor-widget elementor-widget-image"
                                            data-element_type="widget" data-id="abbedf9"
                                            data-widget_type="image.default">
                                          <div class="elementor-widget-container">
                                            <div class="elementor-image">
                                              <img alt class="attachment-ptf-project-thumb size-ptf-project-thumb"
                                                   height="525"
                                                   loading="lazy"
                                                   src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002225700-840x525.jpg"
                                                   width="840"></div>
                                          </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-fade9ad elementor-widget elementor-widget-heading"
                                            data-element_type="widget" data-id="fade9ad"
                                            data-widget_type="heading.default">
                                          <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default mbft-20">framing &
                                              truss</h2></div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-49ff837 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                            data-element_type="widget" data-id="49ff837"
                                            data-widget_type="icon-list.default">
                                          <div class="elementor-widget-container">
                                            <ul class="elementor-icon-list-items">
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Timber Structure</span>
                                              </li>
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Concrete Structure</span>
                                              </li>
                                              <li class="elementor-icon-list-item">
                                                <span class="elementor-icon-list-icon">
                                                  <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                                </span>
                                                <span class="elementor-icon-list-text">Steel Structure</span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-f88574d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="f88574d">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d8fbb16"
                          data-element_type="column" data-id="d8fbb16">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-d670da7 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                data-element_type="widget" data-id="d670da7" data-widget_type="divider.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-a3649fc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="a3649fc">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d8578d7"
                          data-element_type="column" data-id="d8578d7">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-65911fd elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                data-element_type="widget" data-id="65911fd"
                                data-settings="{&quot;navigation&quot;:&quot;arrows&quot;,&quot;slides_to_show&quot;:&quot;1&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}"
                                data-widget_type="image-carousel.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                  <div class="elementor-image-carousel swiper-wrapper">
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181002222237"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002222237-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="cof"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002225853-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="cof"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002225909-840x525.jpg">
                                      </figure>
                                    </div>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-prev">
                                    <i aria-hidden="true" class="eicon-chevron-left"></i>
                                    <span class="elementor-screen-only">Previous</span>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-next">
                                    <i aria-hidden="true" class="eicon-chevron-right"></i>
                                    <span class="elementor-screen-only">Next</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-a8a4e92 elementor-widget elementor-widget-heading"
                                data-element_type="widget" data-id="a8a4e92" data-widget_type="heading.default">
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default mbft-20">Exterior Cladding</h2></div>
                            </div>
                            <div
                                class="elementor-element elementor-element-f23aec4 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-element_type="widget" data-id="f23aec4" data-widget_type="icon-list.default">
                              <div class="elementor-widget-container">
                                <ul class="elementor-icon-list-items">
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
																<i aria-hidden="true" class="fa fa-arrow-right"></i>
													</span>
                                    <span class="elementor-icon-list-text"><span
                                        style="color: rgb(34, 34, 34); font-family: Arial; font-size: 12pt; letter-spacing: 0pt;">Weather board</span></span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
																<i aria-hidden="true" class="fa fa-arrow-right"></i>
													</span>
                                    <span class="elementor-icon-list-text">Cedar</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
																<i aria-hidden="true" class="fa fa-arrow-right"></i>
													</span>
                                    <span class="elementor-icon-list-text"><span
                                        style="color: rgb(34, 34, 34); font-family: Arial; font-size: 12pt; letter-spacing: 0pt;">Brick</span></span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-70c6f17"
                          data-element_type="column" data-id="70c6f17">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-7365153 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                data-element_type="widget" data-id="7365153"
                                data-settings="{&quot;navigation&quot;:&quot;arrows&quot;,&quot;slides_to_show&quot;:&quot;1&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}"
                                data-widget_type="image-carousel.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                  <div class="elementor-image-carousel swiper-wrapper">
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181002222716"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002222716-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="cof"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002230732-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="cof"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181002230012-840x525.jpg">
                                      </figure>
                                    </div>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-prev">
                                    <i aria-hidden="true" class="eicon-chevron-left"></i>
                                    <span class="elementor-screen-only">Previous</span>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-next">
                                    <i aria-hidden="true" class="eicon-chevron-right"></i>
                                    <span class="elementor-screen-only">Next</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-44e9460 elementor-widget elementor-widget-heading"
                                data-element_type="widget" data-id="44e9460" data-widget_type="heading.default">
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default mbft-20">Interior Cladding</h2></div>
                            </div>
                            <div
                                class="elementor-element elementor-element-cfa2b93 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-element_type="widget" data-id="cfa2b93" data-widget_type="icon-list.default">
                              <div class="elementor-widget-container">
                                <ul class="elementor-icon-list-items">
                                  <li class="elementor-icon-list-item">
                                  <span class="elementor-icon-list-icon">
                                        <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                  </span>
                                    <span class="elementor-icon-list-text"><span
                                        style="color: rgb(34, 34, 34); font-family: Arial; font-size: 12pt; letter-spacing: 0pt;">Decoration</span></span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
																<i aria-hidden="true" class="fa fa-arrow-right"></i>
													</span>
                                    <span class="elementor-icon-list-text">Renovation</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
																<i aria-hidden="true" class="fa fa-arrow-right"></i>
													</span>
                                    <span class="elementor-icon-list-text">Insolution</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-dfa4f6b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="dfa4f6b">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-598dd2a"
                          data-element_type="column" data-id="598dd2a">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-460c85f elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                                data-element_type="widget" data-id="460c85f" data-widget_type="divider.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-divider">
                                  <span class="elementor-divider-separator"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-88e3061 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-element_type="section" data-id="88e3061">
                  <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-row">
                      <div
                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ea6eb3"
                          data-element_type="column" data-id="4ea6eb3">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-a58d589 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                data-element_type="widget" data-id="a58d589"
                                data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}"
                                data-widget_type="image-carousel.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                  <div class="elementor-image-carousel swiper-wrapper">
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181024232219"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181024232219.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181024232255"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181024232255-840x525.png">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181024232439"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181024232439-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="微信图片_20181024232446"
                                                                              class="swiper-slide-image"
                                                                              src="images/%C3%A5%C2%BE%C2%AE%C3%A4%C2%BF%C2%A1%C3%A5_%C2%BE%C3%A7___20181024232446-840x525.jpg">
                                      </figure>
                                    </div>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-prev">
                                    <i aria-hidden="true" class="eicon-chevron-left"></i>
                                    <span class="elementor-screen-only">Previous</span>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-next">
                                    <i aria-hidden="true" class="eicon-chevron-right"></i>
                                    <span class="elementor-screen-only">Next</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-57e69cc elementor-widget elementor-widget-heading"
                                data-element_type="widget" data-id="57e69cc" data-widget_type="heading.default">
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default mbft-20">renovation&amp;interior
                                  design</h2></div>
                            </div>
                            <div
                                class="elementor-element elementor-element-ed42114 elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-element_type="widget" data-id="ed42114" data-widget_type="icon-list.default">
                              <div class="elementor-widget-container">
                                <ul class="elementor-icon-list-items">
                                  <li class="elementor-icon-list-item">
                                    <span class="elementor-icon-list-icon">
                                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                    </span>
                                    <span class="elementor-icon-list-text">House Renovation Project</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
                                    <span class="elementor-icon-list-icon">
                                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                    </span>
                                    <span class="elementor-icon-list-text">Renovation Tips</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
                                    <span class="elementor-icon-list-icon">
                                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                    </span>
                                    <span class="elementor-icon-list-text">Renovation Site</span>
                                  </li>
                                  <li class="elementor-icon-list-item">
                                    <span class="elementor-icon-list-icon">
                                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                    </span>
                                    <span class="elementor-icon-list-text">Materials recommended</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                          class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-de5e8d2"
                          data-element_type="column" data-id="de5e8d2">
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                                class="elementor-element elementor-element-223c95d elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel"
                                data-element_type="widget" data-id="223c95d"
                                data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}"
                                data-widget_type="image-carousel.default">
                              <div class="elementor-widget-container">
                                <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                  <div class="elementor-image-carousel swiper-wrapper">
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="267558845479747794"
                                                                              class="swiper-slide-image"
                                                                              src="images/267558845479747794-800x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="86731744683765046"
                                                                              class="swiper-slide-image"
                                                                              src="images/86731744683765046-800x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="pexels-photo-280229"
                                                                              class="swiper-slide-image"
                                                                              src="images/pexels-photo-280229-840x525.jpeg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="beautiful-botanical-child-1352752"
                                                                              class="swiper-slide-image"
                                                                              src="images/beautiful-botanical-child-1352752-840x525.jpg">
                                      </figure>
                                    </div>
                                    <div class="swiper-slide">
                                      <figure class="swiper-slide-inner"><img alt="backyard-bench-daylight-210531"
                                                                              class="swiper-slide-image"
                                                                              src="images/backyard-bench-daylight-210531-840x525.jpg">
                                      </figure>
                                    </div>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-prev">
                                    <i aria-hidden="true" class="eicon-chevron-left"></i>
                                    <span class="elementor-screen-only">Previous</span>
                                  </div>
                                  <div class="elementor-swiper-button elementor-swiper-button-next">
                                    <i aria-hidden="true" class="eicon-chevron-right"></i>
                                    <span class="elementor-screen-only">Next</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-6f5aa62 elementor-widget elementor-widget-heading"
                                data-element_type="widget" data-id="6f5aa62" data-widget_type="heading.default">
                              <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default mbft-20">LandScape<br><br></h2></div>
                            </div>
                            <div
                                class="elementor-element elementor-element-b002dc5 elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-element_type="widget" data-id="b002dc5" data-widget_type="icon-list.default">
                              <div class="elementor-widget-container">
                                <ul class="elementor-icon-list-items">
                                  <li class="elementor-icon-list-item">
                                    <span class="elementor-icon-list-icon">
                                          <i aria-hidden="true" class="fa fa-arrow-right"></i>
                                    </span>
                                    <span class="elementor-icon-list-text">Gardening design experts help you realize the ideal garden of your dreams with over 30 years working experience</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <FooterBar></FooterBar>

          <a class="go-top" href="#"><i class="fa fa-angle-up"></i></a>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";

export default {
  name: 'Service',
  components: {
    NavBar,
    FooterBar
  }
}
</script>
<style lang="scss">

@use '../style/layout';

  @media screen and (max-width: layout.$mobile-width) {
    section {
      padding: 20px 0;
    }    
  }
</style>
