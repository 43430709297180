export const blogs = [
    {
       "id":1,
       "Project_Name":"New Project",
       "Client":"HUO's Client",
       "Date":"2019-12-29",
       "Location":"Auckland",
       "Category":"Apartment",
       "Description":null,
       "published_at":"2021-02-08T01:14:57.431Z",
       "created_at":"2021-02-08T01:13:27.194Z",
       "updated_at":"2021-03-07T02:46:13.567Z",
       "Images":[
          {
             "id":35,
             "name":"å¾®ä¿¡å_¾ç___20200617094607-scaled.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2560,
             "height":1920,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20200617094607-scaled.jpg",
                   "hash":"thumbnail_a_ae_a_c_20200617094607_scaled_17f9d29910",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":5.96,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20200617094607_scaled_17f9d29910.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20200617094607-scaled.jpg",
                   "hash":"large_a_ae_a_c_20200617094607_scaled_17f9d29910",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":84.96,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20200617094607_scaled_17f9d29910.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20200617094607-scaled.jpg",
                   "hash":"medium_a_ae_a_c_20200617094607_scaled_17f9d29910",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":51.49,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20200617094607_scaled_17f9d29910.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20200617094607-scaled.jpg",
                   "hash":"small_a_ae_a_c_20200617094607_scaled_17f9d29910",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":25.06,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20200617094607_scaled_17f9d29910.jpg"
                }
             },
             "hash":"a_ae_a_c_20200617094607_scaled_17f9d29910",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":429.6,
             "url":"/uploads/a_ae_a_c_20200617094607_scaled_17f9d29910.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-03-07T02:46:04.989Z",
             "updated_at":"2021-03-07T02:46:05.008Z"
          },
          {
             "id":36,
             "name":"å¾®ä¿¡å_¾ç___20200617094620-scaled.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2560,
             "height":1920,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20200617094620-scaled.jpg",
                   "hash":"thumbnail_a_ae_a_c_20200617094620_scaled_cf98b6c35f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":6.23,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20200617094620_scaled_cf98b6c35f.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20200617094620-scaled.jpg",
                   "hash":"large_a_ae_a_c_20200617094620_scaled_cf98b6c35f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":82.79,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20200617094620_scaled_cf98b6c35f.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20200617094620-scaled.jpg",
                   "hash":"medium_a_ae_a_c_20200617094620_scaled_cf98b6c35f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":52.22,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20200617094620_scaled_cf98b6c35f.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20200617094620-scaled.jpg",
                   "hash":"small_a_ae_a_c_20200617094620_scaled_cf98b6c35f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":26.18,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20200617094620_scaled_cf98b6c35f.jpg"
                }
             },
             "hash":"a_ae_a_c_20200617094620_scaled_cf98b6c35f",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":363.98,
             "url":"/uploads/a_ae_a_c_20200617094620_scaled_cf98b6c35f.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-03-07T02:46:05.031Z",
             "updated_at":"2021-03-07T02:46:05.043Z"
          },
          {
             "id":37,
             "name":"å¾®ä¿¡å_¾ç___20200617094614-scaled.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2560,
             "height":1920,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20200617094614-scaled.jpg",
                   "hash":"thumbnail_a_ae_a_c_20200617094614_scaled_aa2e9123c1",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":6.26,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20200617094614_scaled_aa2e9123c1.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20200617094614-scaled.jpg",
                   "hash":"large_a_ae_a_c_20200617094614_scaled_aa2e9123c1",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":86.54,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20200617094614_scaled_aa2e9123c1.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20200617094614-scaled.jpg",
                   "hash":"medium_a_ae_a_c_20200617094614_scaled_aa2e9123c1",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":53.65,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20200617094614_scaled_aa2e9123c1.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20200617094614-scaled.jpg",
                   "hash":"small_a_ae_a_c_20200617094614_scaled_aa2e9123c1",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":26.82,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20200617094614_scaled_aa2e9123c1.jpg"
                }
             },
             "hash":"a_ae_a_c_20200617094614_scaled_aa2e9123c1",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":396.86,
             "url":"/uploads/a_ae_a_c_20200617094614_scaled_aa2e9123c1.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-03-07T02:46:05.063Z",
             "updated_at":"2021-03-07T02:46:05.080Z"
          }
       ]
    },
    {
       "id":2,
       "Project_Name":"UNIT 29-34, 101 HARBOUR DRIVE, MATARANGI, COROMANDEL",
       "Client":"Coromandel",
       "Date":"2018-12-01",
       "Location":"Coromandel",
       "Category":"LuxuryVilla",
       "Description":"简介\n\n霍氏建筑2018年大型项目101坐落于美丽的Coromandel半岛，Matarangi高档小镇明星高尔夫球场内，本公司在面临当地施工人员不足，材料不足的情况下，迎难而上，据了解由于小镇人少，建筑资源缺稀，一个新的二层民宅需要18个月交工，而我们在半年左右的时间完成了6个units的建造，目前在收尾阶段。真正做到了为屋主节约贷款周期，并且在当地工人，材料高价的情况下，做到了节约成本，高质量的建造，在施工速度，规模和质量上使当地同行以及材料供应商刮目相看。\n\n特点\n\n规模大，施工人员充足，完成速度快，工程质量高，建筑成本低",
       "published_at":"2021-02-08T01:14:50.445Z",
       "created_at":"2021-02-08T01:14:48.016Z",
       "updated_at":"2021-03-07T02:48:06.037Z",
       "Images":[
          {
             "id":2,
             "name":"71aa3425f8bb6981f4704defe311056.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_71aa3425f8bb6981f4704defe311056.jpg",
                   "hash":"thumbnail_71aa3425f8bb6981f4704defe311056_f65e6dd79c",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.54,
                   "path":null,
                   "url":"/uploads/thumbnail_71aa3425f8bb6981f4704defe311056_f65e6dd79c.jpg"
                },
                "large":{
                   "name":"large_71aa3425f8bb6981f4704defe311056.jpg",
                   "hash":"large_71aa3425f8bb6981f4704defe311056_f65e6dd79c",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":103.64,
                   "path":null,
                   "url":"/uploads/large_71aa3425f8bb6981f4704defe311056_f65e6dd79c.jpg"
                },
                "medium":{
                   "name":"medium_71aa3425f8bb6981f4704defe311056.jpg",
                   "hash":"medium_71aa3425f8bb6981f4704defe311056_f65e6dd79c",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":65.06,
                   "path":null,
                   "url":"/uploads/medium_71aa3425f8bb6981f4704defe311056_f65e6dd79c.jpg"
                },
                "small":{
                   "name":"small_71aa3425f8bb6981f4704defe311056.jpg",
                   "hash":"small_71aa3425f8bb6981f4704defe311056_f65e6dd79c",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":32,
                   "path":null,
                   "url":"/uploads/small_71aa3425f8bb6981f4704defe311056_f65e6dd79c.jpg"
                }
             },
             "hash":"71aa3425f8bb6981f4704defe311056_f65e6dd79c",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":133.4,
             "url":"/uploads/71aa3425f8bb6981f4704defe311056_f65e6dd79c.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:30:18.880Z",
             "updated_at":"2021-02-08T01:30:18.913Z"
          },
          {
             "id":3,
             "name":"1c6ce781452345653af45ea891bcd81.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_1c6ce781452345653af45ea891bcd81.jpg",
                   "hash":"thumbnail_1c6ce781452345653af45ea891bcd81_d7eaecdd62",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":9.47,
                   "path":null,
                   "url":"/uploads/thumbnail_1c6ce781452345653af45ea891bcd81_d7eaecdd62.jpg"
                },
                "large":{
                   "name":"large_1c6ce781452345653af45ea891bcd81.jpg",
                   "hash":"large_1c6ce781452345653af45ea891bcd81_d7eaecdd62",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":147.43,
                   "path":null,
                   "url":"/uploads/large_1c6ce781452345653af45ea891bcd81_d7eaecdd62.jpg"
                },
                "medium":{
                   "name":"medium_1c6ce781452345653af45ea891bcd81.jpg",
                   "hash":"medium_1c6ce781452345653af45ea891bcd81_d7eaecdd62",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":94.36,
                   "path":null,
                   "url":"/uploads/medium_1c6ce781452345653af45ea891bcd81_d7eaecdd62.jpg"
                },
                "small":{
                   "name":"small_1c6ce781452345653af45ea891bcd81.jpg",
                   "hash":"small_1c6ce781452345653af45ea891bcd81_d7eaecdd62",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":46.41,
                   "path":null,
                   "url":"/uploads/small_1c6ce781452345653af45ea891bcd81_d7eaecdd62.jpg"
                }
             },
             "hash":"1c6ce781452345653af45ea891bcd81_d7eaecdd62",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":180.92,
             "url":"/uploads/1c6ce781452345653af45ea891bcd81_d7eaecdd62.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:30:18.938Z",
             "updated_at":"2021-02-08T01:30:18.951Z"
          },
          {
             "id":4,
             "name":"9f7053631f69395e39ee7cf07a636b3.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2581,
             "height":1079,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_9f7053631f69395e39ee7cf07a636b3.jpg",
                   "hash":"thumbnail_9f7053631f69395e39ee7cf07a636b3_f1e616a34a",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":245,
                   "height":102,
                   "size":7.74,
                   "path":null,
                   "url":"/uploads/thumbnail_9f7053631f69395e39ee7cf07a636b3_f1e616a34a.jpg"
                },
                "large":{
                   "name":"large_9f7053631f69395e39ee7cf07a636b3.jpg",
                   "hash":"large_9f7053631f69395e39ee7cf07a636b3_f1e616a34a",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":418,
                   "size":79.84,
                   "path":null,
                   "url":"/uploads/large_9f7053631f69395e39ee7cf07a636b3_f1e616a34a.jpg"
                },
                "medium":{
                   "name":"medium_9f7053631f69395e39ee7cf07a636b3.jpg",
                   "hash":"medium_9f7053631f69395e39ee7cf07a636b3_f1e616a34a",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":314,
                   "size":50.48,
                   "path":null,
                   "url":"/uploads/medium_9f7053631f69395e39ee7cf07a636b3_f1e616a34a.jpg"
                },
                "small":{
                   "name":"small_9f7053631f69395e39ee7cf07a636b3.jpg",
                   "hash":"small_9f7053631f69395e39ee7cf07a636b3_f1e616a34a",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":209,
                   "size":25.26,
                   "path":null,
                   "url":"/uploads/small_9f7053631f69395e39ee7cf07a636b3_f1e616a34a.jpg"
                }
             },
             "hash":"9f7053631f69395e39ee7cf07a636b3_f1e616a34a",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":275.19,
             "url":"/uploads/9f7053631f69395e39ee7cf07a636b3_f1e616a34a.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:30:18.968Z",
             "updated_at":"2021-02-08T01:30:18.985Z"
          },
          {
             "id":5,
             "name":"d078b6b83d1e91c49428f33573e6d75.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_d078b6b83d1e91c49428f33573e6d75.jpg",
                   "hash":"thumbnail_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":9.83,
                   "path":null,
                   "url":"/uploads/thumbnail_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0.jpg"
                },
                "large":{
                   "name":"large_d078b6b83d1e91c49428f33573e6d75.jpg",
                   "hash":"large_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":167.1,
                   "path":null,
                   "url":"/uploads/large_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0.jpg"
                },
                "medium":{
                   "name":"medium_d078b6b83d1e91c49428f33573e6d75.jpg",
                   "hash":"medium_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":106.51,
                   "path":null,
                   "url":"/uploads/medium_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0.jpg"
                },
                "small":{
                   "name":"small_d078b6b83d1e91c49428f33573e6d75.jpg",
                   "hash":"small_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":51.04,
                   "path":null,
                   "url":"/uploads/small_d078b6b83d1e91c49428f33573e6d75_6045ecb6b0.jpg"
                }
             },
             "hash":"d078b6b83d1e91c49428f33573e6d75_6045ecb6b0",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":204.67,
             "url":"/uploads/d078b6b83d1e91c49428f33573e6d75_6045ecb6b0.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:30:19.004Z",
             "updated_at":"2021-02-08T01:30:19.018Z"
          },
          {
             "id":1,
             "name":"166b1a032e09ccb9849c09e65b451e6.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_166b1a032e09ccb9849c09e65b451e6.jpg",
                   "hash":"thumbnail_166b1a032e09ccb9849c09e65b451e6_f7f34b741f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.48,
                   "path":null,
                   "url":"/uploads/thumbnail_166b1a032e09ccb9849c09e65b451e6_f7f34b741f.jpg"
                },
                "large":{
                   "name":"large_166b1a032e09ccb9849c09e65b451e6.jpg",
                   "hash":"large_166b1a032e09ccb9849c09e65b451e6_f7f34b741f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":113.5,
                   "path":null,
                   "url":"/uploads/large_166b1a032e09ccb9849c09e65b451e6_f7f34b741f.jpg"
                },
                "medium":{
                   "name":"medium_166b1a032e09ccb9849c09e65b451e6.jpg",
                   "hash":"medium_166b1a032e09ccb9849c09e65b451e6_f7f34b741f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":70.41,
                   "path":null,
                   "url":"/uploads/medium_166b1a032e09ccb9849c09e65b451e6_f7f34b741f.jpg"
                },
                "small":{
                   "name":"small_166b1a032e09ccb9849c09e65b451e6.jpg",
                   "hash":"small_166b1a032e09ccb9849c09e65b451e6_f7f34b741f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":34.11,
                   "path":null,
                   "url":"/uploads/small_166b1a032e09ccb9849c09e65b451e6_f7f34b741f.jpg"
                }
             },
             "hash":"166b1a032e09ccb9849c09e65b451e6_f7f34b741f",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":150.62,
             "url":"/uploads/166b1a032e09ccb9849c09e65b451e6_f7f34b741f.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:30:18.837Z",
             "updated_at":"2021-02-08T01:30:18.857Z"
          }
       ]
    },
    {
       "id":3,
       "Project_Name":"NORTHSHORE RESIDENTIAL",
       "Client":"Northshore",
       "Date":"2018-01-12",
       "Location":"Northshore",
       "Category":"Building",
       "Description":null,
       "published_at":"2021-02-08T01:36:38.729Z",
       "created_at":"2021-02-08T01:36:32.888Z",
       "updated_at":"2021-02-08T01:36:38.755Z",
       "Images":[
          {
             "id":7,
             "name":"residential-northshore.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_residential-northshore.jpg",
                   "hash":"thumbnail_residential_northshore_c0e267eeff",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":8.53,
                   "path":null,
                   "url":"/uploads/thumbnail_residential_northshore_c0e267eeff.jpg"
                },
                "large":{
                   "name":"large_residential-northshore.jpg",
                   "hash":"large_residential_northshore_c0e267eeff",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":117.67,
                   "path":null,
                   "url":"/uploads/large_residential_northshore_c0e267eeff.jpg"
                },
                "medium":{
                   "name":"medium_residential-northshore.jpg",
                   "hash":"medium_residential_northshore_c0e267eeff",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":74.87,
                   "path":null,
                   "url":"/uploads/medium_residential_northshore_c0e267eeff.jpg"
                },
                "small":{
                   "name":"small_residential-northshore.jpg",
                   "hash":"small_residential_northshore_c0e267eeff",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":37.76,
                   "path":null,
                   "url":"/uploads/small_residential_northshore_c0e267eeff.jpg"
                }
             },
             "hash":"residential_northshore_c0e267eeff",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":147.65,
             "url":"/uploads/residential_northshore_c0e267eeff.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:36:21.486Z",
             "updated_at":"2021-02-08T01:36:21.504Z"
          }
       ]
    },
    {
       "id":4,
       "Project_Name":"ALBANY TELEHOUSE",
       "Client":" Albany Telehouse",
       "Date":"2019-04-01",
       "Location":"Albany",
       "Category":"Processing",
       "Description":null,
       "published_at":"2021-02-08T01:39:37.894Z",
       "created_at":"2021-02-08T01:39:35.422Z",
       "updated_at":"2021-02-08T01:39:37.922Z",
       "Images":[
          {
             "id":8,
             "name":"4b441da205e3175223a8d610e963d8e.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_4b441da205e3175223a8d610e963d8e.jpg",
                   "hash":"thumbnail_4b441da205e3175223a8d610e963d8e_80da4033e7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":8.25,
                   "path":null,
                   "url":"/uploads/thumbnail_4b441da205e3175223a8d610e963d8e_80da4033e7.jpg"
                },
                "large":{
                   "name":"large_4b441da205e3175223a8d610e963d8e.jpg",
                   "hash":"large_4b441da205e3175223a8d610e963d8e_80da4033e7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":119.67,
                   "path":null,
                   "url":"/uploads/large_4b441da205e3175223a8d610e963d8e_80da4033e7.jpg"
                },
                "medium":{
                   "name":"medium_4b441da205e3175223a8d610e963d8e.jpg",
                   "hash":"medium_4b441da205e3175223a8d610e963d8e_80da4033e7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":75.22,
                   "path":null,
                   "url":"/uploads/medium_4b441da205e3175223a8d610e963d8e_80da4033e7.jpg"
                },
                "small":{
                   "name":"small_4b441da205e3175223a8d610e963d8e.jpg",
                   "hash":"small_4b441da205e3175223a8d610e963d8e_80da4033e7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":36.83,
                   "path":null,
                   "url":"/uploads/small_4b441da205e3175223a8d610e963d8e_80da4033e7.jpg"
                }
             },
             "hash":"4b441da205e3175223a8d610e963d8e_80da4033e7",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":155.21,
             "url":"/uploads/4b441da205e3175223a8d610e963d8e_80da4033e7.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:39:29.287Z",
             "updated_at":"2021-02-08T01:39:29.310Z"
          },
          {
             "id":9,
             "name":"1697b83d065fc0dfa4642ac9823d44d.jpg",
             "alternativeText":"",
             "caption":"",
             "width":3648,
             "height":2736,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_1697b83d065fc0dfa4642ac9823d44d.jpg",
                   "hash":"thumbnail_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.41,
                   "path":null,
                   "url":"/uploads/thumbnail_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9.jpg"
                },
                "large":{
                   "name":"large_1697b83d065fc0dfa4642ac9823d44d.jpg",
                   "hash":"large_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":113.15,
                   "path":null,
                   "url":"/uploads/large_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9.jpg"
                },
                "medium":{
                   "name":"medium_1697b83d065fc0dfa4642ac9823d44d.jpg",
                   "hash":"medium_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":67.39,
                   "path":null,
                   "url":"/uploads/medium_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9.jpg"
                },
                "small":{
                   "name":"small_1697b83d065fc0dfa4642ac9823d44d.jpg",
                   "hash":"small_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":33.47,
                   "path":null,
                   "url":"/uploads/small_1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9.jpg"
                }
             },
             "hash":"1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":938.07,
             "url":"/uploads/1697b83d065fc0dfa4642ac9823d44d_1e5960c8c9.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:39:29.452Z",
             "updated_at":"2021-02-08T01:39:29.480Z"
          },
          {
             "id":10,
             "name":"a1f56a1cc2a1aed066602847906c956.jpg",
             "alternativeText":"",
             "caption":"",
             "width":3648,
             "height":2736,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_a1f56a1cc2a1aed066602847906c956.jpg",
                   "hash":"thumbnail_a1f56a1cc2a1aed066602847906c956_27b92c338b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":11.08,
                   "path":null,
                   "url":"/uploads/thumbnail_a1f56a1cc2a1aed066602847906c956_27b92c338b.jpg"
                },
                "large":{
                   "name":"large_a1f56a1cc2a1aed066602847906c956.jpg",
                   "hash":"large_a1f56a1cc2a1aed066602847906c956_27b92c338b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":147.97,
                   "path":null,
                   "url":"/uploads/large_a1f56a1cc2a1aed066602847906c956_27b92c338b.jpg"
                },
                "medium":{
                   "name":"medium_a1f56a1cc2a1aed066602847906c956.jpg",
                   "hash":"medium_a1f56a1cc2a1aed066602847906c956_27b92c338b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":92.52,
                   "path":null,
                   "url":"/uploads/medium_a1f56a1cc2a1aed066602847906c956_27b92c338b.jpg"
                },
                "small":{
                   "name":"small_a1f56a1cc2a1aed066602847906c956.jpg",
                   "hash":"small_a1f56a1cc2a1aed066602847906c956_27b92c338b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":48.75,
                   "path":null,
                   "url":"/uploads/small_a1f56a1cc2a1aed066602847906c956_27b92c338b.jpg"
                }
             },
             "hash":"a1f56a1cc2a1aed066602847906c956_27b92c338b",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":1050.7,
             "url":"/uploads/a1f56a1cc2a1aed066602847906c956_27b92c338b.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:39:29.508Z",
             "updated_at":"2021-02-08T01:39:29.533Z"
          }
       ]
    },
    {
       "id":5,
       "Project_Name":"GOLF VILLAS-OUTSIDE AUCKLAND",
       "Client":" Golf Club",
       "Date":null,
       "Location":" Golf course",
       "Category":"Commercial",
       "Description":"The 6 golf villas start construction at the same time together, which is a contest between speed and passion.",
       "published_at":"2021-02-08T01:41:29.257Z",
       "created_at":"2021-02-08T01:41:25.593Z",
       "updated_at":"2021-03-07T02:48:59.405Z",
       "Images":[
          {
             "id":12,
             "name":"å¾®ä¿¡å_¾ç___20190212213413.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212213413.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212213413_0ca515a202",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.93,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212213413_0ca515a202.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212213413.jpg",
                   "hash":"large_a_ae_a_c_20190212213413_0ca515a202",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":142.14,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212213413_0ca515a202.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212213413.jpg",
                   "hash":"medium_a_ae_a_c_20190212213413_0ca515a202",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":84.49,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212213413_0ca515a202.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212213413.jpg",
                   "hash":"small_a_ae_a_c_20190212213413_0ca515a202",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":38.75,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212213413_0ca515a202.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212213413_0ca515a202",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":237.9,
             "url":"/uploads/a_ae_a_c_20190212213413_0ca515a202.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:41:21.800Z",
             "updated_at":"2021-02-08T01:41:21.820Z"
          },
          {
             "id":11,
             "name":"å¾®ä¿¡å_¾ç___20190212213956.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1440,
             "height":1080,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212213956.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212213956_720fc49b3b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.73,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212213956_720fc49b3b.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212213956.jpg",
                   "hash":"large_a_ae_a_c_20190212213956_720fc49b3b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":110.76,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212213956_720fc49b3b.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212213956.jpg",
                   "hash":"medium_a_ae_a_c_20190212213956_720fc49b3b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":71.12,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212213956_720fc49b3b.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212213956.jpg",
                   "hash":"small_a_ae_a_c_20190212213956_720fc49b3b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":35.72,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212213956_720fc49b3b.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212213956_720fc49b3b",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":118.28,
             "url":"/uploads/a_ae_a_c_20190212213956_720fc49b3b.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:41:21.733Z",
             "updated_at":"2021-02-08T01:41:21.756Z"
          }
       ]
    },
    {
       "id":6,
       "Project_Name":"BRIDGE REPAIRS",
       "Client":"Golf Club",
       "Date":null,
       "Location":"Golf course",
       "Category":"Building",
       "Description":"Bridge repairs over 100 meters in length on a picturesque golf course",
       "published_at":"2021-02-08T01:42:49.744Z",
       "created_at":"2021-02-08T01:42:47.051Z",
       "updated_at":"2021-02-08T01:42:49.772Z",
       "Images":[
          {
             "id":13,
             "name":"å¾®ä¿¡å_¾ç___20190212212957.jpg",
             "alternativeText":"",
             "caption":"",
             "width":490,
             "height":363,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212212957.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212212957_a75f9c014b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":211,
                   "height":156,
                   "size":8.78,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212212957_a75f9c014b.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212212957_a75f9c014b",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":44.74,
             "url":"/uploads/a_ae_a_c_20190212212957_a75f9c014b.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:42:42.638Z",
             "updated_at":"2021-02-08T01:42:42.660Z"
          },
          {
             "id":14,
             "name":"å¾®ä¿¡å_¾ç___20190212213011.jpg",
             "alternativeText":"",
             "caption":"",
             "width":488,
             "height":364,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212213011.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212213011_4e8c1584ac",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":209,
                   "height":156,
                   "size":10.68,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212213011_4e8c1584ac.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212213011_4e8c1584ac",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":51.6,
             "url":"/uploads/a_ae_a_c_20190212213011_4e8c1584ac.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:42:42.693Z",
             "updated_at":"2021-02-08T01:42:42.710Z"
          },
          {
             "id":15,
             "name":"å¾®ä¿¡å_¾ç___20190212213033.jpg",
             "alternativeText":"",
             "caption":"",
             "width":960,
             "height":1280,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212213033.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212213033_8a6f060e6f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":117,
                   "height":156,
                   "size":5.19,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212213033_8a6f060e6f.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212213033.jpg",
                   "hash":"large_a_ae_a_c_20190212213033_8a6f060e6f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":1000,
                   "size":170.17,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212213033_8a6f060e6f.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212213033.jpg",
                   "hash":"medium_a_ae_a_c_20190212213033_8a6f060e6f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":563,
                   "height":750,
                   "size":99.76,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212213033_8a6f060e6f.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212213033.jpg",
                   "hash":"small_a_ae_a_c_20190212213033_8a6f060e6f",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":375,
                   "height":500,
                   "size":44.36,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212213033_8a6f060e6f.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212213033_8a6f060e6f",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":235.31,
             "url":"/uploads/a_ae_a_c_20190212213033_8a6f060e6f.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:42:42.838Z",
             "updated_at":"2021-02-08T01:42:42.854Z"
          },
          {
             "id":16,
             "name":"å¾®ä¿¡å_¾ç___20190212213300.jpg",
             "alternativeText":"",
             "caption":"",
             "width":960,
             "height":1280,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212213300.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212213300_65f2b9eecd",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":117,
                   "height":156,
                   "size":4.88,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212213300_65f2b9eecd.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212213300.jpg",
                   "hash":"large_a_ae_a_c_20190212213300_65f2b9eecd",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":1000,
                   "size":239.61,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212213300_65f2b9eecd.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212213300.jpg",
                   "hash":"medium_a_ae_a_c_20190212213300_65f2b9eecd",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":563,
                   "height":750,
                   "size":132.92,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212213300_65f2b9eecd.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212213300.jpg",
                   "hash":"small_a_ae_a_c_20190212213300_65f2b9eecd",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":375,
                   "height":500,
                   "size":53.34,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212213300_65f2b9eecd.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212213300_65f2b9eecd",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":341.96,
             "url":"/uploads/a_ae_a_c_20190212213300_65f2b9eecd.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:42:42.875Z",
             "updated_at":"2021-02-08T01:42:42.893Z"
          },
          {
             "id":17,
             "name":"å¾®ä¿¡å_¾ç___20190212212943.png",
             "alternativeText":"",
             "caption":"",
             "width":2156,
             "height":628,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212212943.png",
                   "hash":"thumbnail_a_ae_a_c_20190212212943_fff1911ce8",
                   "ext":".png",
                   "mime":"image/png",
                   "width":245,
                   "height":71,
                   "size":50.78,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212212943_fff1911ce8.png"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212212943.png",
                   "hash":"large_a_ae_a_c_20190212212943_fff1911ce8",
                   "ext":".png",
                   "mime":"image/png",
                   "width":1000,
                   "height":291,
                   "size":769.59,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212212943_fff1911ce8.png"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212212943.png",
                   "hash":"medium_a_ae_a_c_20190212212943_fff1911ce8",
                   "ext":".png",
                   "mime":"image/png",
                   "width":750,
                   "height":218,
                   "size":440.03,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212212943_fff1911ce8.png"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212212943.png",
                   "hash":"small_a_ae_a_c_20190212212943_fff1911ce8",
                   "ext":".png",
                   "mime":"image/png",
                   "width":500,
                   "height":146,
                   "size":199.95,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212212943_fff1911ce8.png"
                }
             },
             "hash":"a_ae_a_c_20190212212943_fff1911ce8",
             "ext":".png",
             "mime":"image/png",
             "size":3189.37,
             "url":"/uploads/a_ae_a_c_20190212212943_fff1911ce8.png",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:42:43.230Z",
             "updated_at":"2021-02-08T01:42:43.245Z"
          }
       ]
    },
    {
       "id":7,
       "Project_Name":"AUCKLAND’S CENTRAL DISTRICT RESIDENTIAL PROJECT",
       "Client":"Scriect",
       "Date":"2018-12-22",
       "Location":"Auckland Central Distrist",
       "Category":"Processing",
       "Description":"Auckland’s Central District residential project is fully invested from design to construction\n\n",
       "published_at":"2021-02-08T01:44:47.472Z",
       "created_at":"2021-02-08T01:44:44.087Z",
       "updated_at":"2021-02-08T01:44:47.499Z",
       "Images":[
          {
             "id":18,
             "name":"å¾®ä¿¡å_¾ç___20190212211455.jpg",
             "alternativeText":"",
             "caption":"",
             "width":4032,
             "height":3024,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212211455.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212211455_1beea28aa4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.83,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212211455_1beea28aa4.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212211455.jpg",
                   "hash":"large_a_ae_a_c_20190212211455_1beea28aa4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":139.49,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212211455_1beea28aa4.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212211455.jpg",
                   "hash":"medium_a_ae_a_c_20190212211455_1beea28aa4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":82.31,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212211455_1beea28aa4.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212211455.jpg",
                   "hash":"small_a_ae_a_c_20190212211455_1beea28aa4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":37.16,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212211455_1beea28aa4.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212211455_1beea28aa4",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":1765.32,
             "url":"/uploads/a_ae_a_c_20190212211455_1beea28aa4.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:44:38.381Z",
             "updated_at":"2021-02-08T01:44:38.403Z"
          },
          {
             "id":19,
             "name":"å¾®ä¿¡å_¾ç___20190212211343.jpg",
             "alternativeText":"",
             "caption":"",
             "width":4032,
             "height":3024,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212211343.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212211343_b37b4c6ae4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":9.1,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212211343_b37b4c6ae4.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212211343.jpg",
                   "hash":"large_a_ae_a_c_20190212211343_b37b4c6ae4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":166.7,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212211343_b37b4c6ae4.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212211343.jpg",
                   "hash":"medium_a_ae_a_c_20190212211343_b37b4c6ae4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":97.58,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212211343_b37b4c6ae4.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212211343.jpg",
                   "hash":"small_a_ae_a_c_20190212211343_b37b4c6ae4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":44.1,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212211343_b37b4c6ae4.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212211343_b37b4c6ae4",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":1876.91,
             "url":"/uploads/a_ae_a_c_20190212211343_b37b4c6ae4.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:44:38.427Z",
             "updated_at":"2021-02-08T01:44:38.445Z"
          },
          {
             "id":20,
             "name":"å¾®ä¿¡å_¾ç___20190212211930.jpg",
             "alternativeText":"",
             "caption":"",
             "width":4032,
             "height":3024,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_å¾®ä¿¡å_¾ç___20190212211930.jpg",
                   "hash":"thumbnail_a_ae_a_c_20190212211930_e6ad92191d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.62,
                   "path":null,
                   "url":"/uploads/thumbnail_a_ae_a_c_20190212211930_e6ad92191d.jpg"
                },
                "large":{
                   "name":"large_å¾®ä¿¡å_¾ç___20190212211930.jpg",
                   "hash":"large_a_ae_a_c_20190212211930_e6ad92191d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":750,
                   "size":141.57,
                   "path":null,
                   "url":"/uploads/large_a_ae_a_c_20190212211930_e6ad92191d.jpg"
                },
                "medium":{
                   "name":"medium_å¾®ä¿¡å_¾ç___20190212211930.jpg",
                   "hash":"medium_a_ae_a_c_20190212211930_e6ad92191d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":81.47,
                   "path":null,
                   "url":"/uploads/medium_a_ae_a_c_20190212211930_e6ad92191d.jpg"
                },
                "small":{
                   "name":"small_å¾®ä¿¡å_¾ç___20190212211930.jpg",
                   "hash":"small_a_ae_a_c_20190212211930_e6ad92191d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":36.84,
                   "path":null,
                   "url":"/uploads/small_a_ae_a_c_20190212211930_e6ad92191d.jpg"
                }
             },
             "hash":"a_ae_a_c_20190212211930_e6ad92191d",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":1892.88,
             "url":"/uploads/a_ae_a_c_20190212211930_e6ad92191d.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:44:38.470Z",
             "updated_at":"2021-02-08T01:44:38.490Z"
          }
       ]
    },
    {
       "id":8,
       "Project_Name":"HOBSONVILLE RESIDENTIAL",
       "Client":"Hobsonville",
       "Date":"2018-11-01",
       "Location":"Hobsonville",
       "Category":"Building",
       "Description":null,
       "published_at":"2021-02-08T01:47:51.173Z",
       "created_at":"2021-02-08T01:47:47.075Z",
       "updated_at":"2021-02-08T01:47:51.201Z",
       "Images":[
          {
             "id":21,
             "name":"9223bac19c5056630686c96ec9ccc90.jpg",
             "alternativeText":"",
             "caption":"",
             "width":800,
             "height":600,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_9223bac19c5056630686c96ec9ccc90.jpg",
                   "hash":"thumbnail_9223bac19c5056630686c96ec9ccc90_d6b022b405",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":6.93,
                   "path":null,
                   "url":"/uploads/thumbnail_9223bac19c5056630686c96ec9ccc90_d6b022b405.jpg"
                },
                "medium":{
                   "name":"medium_9223bac19c5056630686c96ec9ccc90.jpg",
                   "hash":"medium_9223bac19c5056630686c96ec9ccc90_d6b022b405",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":48.03,
                   "path":null,
                   "url":"/uploads/medium_9223bac19c5056630686c96ec9ccc90_d6b022b405.jpg"
                },
                "small":{
                   "name":"small_9223bac19c5056630686c96ec9ccc90.jpg",
                   "hash":"small_9223bac19c5056630686c96ec9ccc90_d6b022b405",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":25.59,
                   "path":null,
                   "url":"/uploads/small_9223bac19c5056630686c96ec9ccc90_d6b022b405.jpg"
                }
             },
             "hash":"9223bac19c5056630686c96ec9ccc90_d6b022b405",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":53.11,
             "url":"/uploads/9223bac19c5056630686c96ec9ccc90_d6b022b405.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:47:43.859Z",
             "updated_at":"2021-02-08T01:47:43.878Z"
          },
          {
             "id":22,
             "name":"a724bccfa3fb6af2adfcce04e68992c.jpg",
             "alternativeText":"",
             "caption":"",
             "width":800,
             "height":600,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_a724bccfa3fb6af2adfcce04e68992c.jpg",
                   "hash":"thumbnail_a724bccfa3fb6af2adfcce04e68992c_509684d421",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":7.3,
                   "path":null,
                   "url":"/uploads/thumbnail_a724bccfa3fb6af2adfcce04e68992c_509684d421.jpg"
                },
                "medium":{
                   "name":"medium_a724bccfa3fb6af2adfcce04e68992c.jpg",
                   "hash":"medium_a724bccfa3fb6af2adfcce04e68992c_509684d421",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":53.79,
                   "path":null,
                   "url":"/uploads/medium_a724bccfa3fb6af2adfcce04e68992c_509684d421.jpg"
                },
                "small":{
                   "name":"small_a724bccfa3fb6af2adfcce04e68992c.jpg",
                   "hash":"small_a724bccfa3fb6af2adfcce04e68992c_509684d421",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":27.99,
                   "path":null,
                   "url":"/uploads/small_a724bccfa3fb6af2adfcce04e68992c_509684d421.jpg"
                }
             },
             "hash":"a724bccfa3fb6af2adfcce04e68992c_509684d421",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":59.69,
             "url":"/uploads/a724bccfa3fb6af2adfcce04e68992c_509684d421.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:47:43.901Z",
             "updated_at":"2021-02-08T01:47:43.917Z"
          },
          {
             "id":23,
             "name":"6e76284c4dd974c9d68a38774d5896c.jpg",
             "alternativeText":"",
             "caption":"",
             "width":600,
             "height":800,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_6e76284c4dd974c9d68a38774d5896c.jpg",
                   "hash":"thumbnail_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":117,
                   "height":156,
                   "size":3.78,
                   "path":null,
                   "url":"/uploads/thumbnail_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3.jpg"
                },
                "medium":{
                   "name":"medium_6e76284c4dd974c9d68a38774d5896c.jpg",
                   "hash":"medium_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":563,
                   "height":750,
                   "size":38.72,
                   "path":null,
                   "url":"/uploads/medium_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3.jpg"
                },
                "small":{
                   "name":"small_6e76284c4dd974c9d68a38774d5896c.jpg",
                   "hash":"small_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":375,
                   "height":500,
                   "size":20.53,
                   "path":null,
                   "url":"/uploads/small_6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3.jpg"
                }
             },
             "hash":"6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":43.07,
             "url":"/uploads/6e76284c4dd974c9d68a38774d5896c_d6b9b0c1f3.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:47:43.941Z",
             "updated_at":"2021-02-08T01:47:43.958Z"
          },
          {
             "id":24,
             "name":"bf40192c364ecd0e9914cc95f2b2235.jpg",
             "alternativeText":"",
             "caption":"",
             "width":600,
             "height":800,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_bf40192c364ecd0e9914cc95f2b2235.jpg",
                   "hash":"thumbnail_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":117,
                   "height":156,
                   "size":3.58,
                   "path":null,
                   "url":"/uploads/thumbnail_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d.jpg"
                },
                "medium":{
                   "name":"medium_bf40192c364ecd0e9914cc95f2b2235.jpg",
                   "hash":"medium_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":563,
                   "height":750,
                   "size":42.49,
                   "path":null,
                   "url":"/uploads/medium_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d.jpg"
                },
                "small":{
                   "name":"small_bf40192c364ecd0e9914cc95f2b2235.jpg",
                   "hash":"small_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":375,
                   "height":500,
                   "size":21.94,
                   "path":null,
                   "url":"/uploads/small_bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d.jpg"
                }
             },
             "hash":"bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":47.43,
             "url":"/uploads/bf40192c364ecd0e9914cc95f2b2235_3e4d6ab65d.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:47:43.983Z",
             "updated_at":"2021-02-08T01:47:43.999Z"
          },
          {
             "id":25,
             "name":"hobson-view.jpg",
             "alternativeText":"",
             "caption":"",
             "width":800,
             "height":600,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_hobson-view.jpg",
                   "hash":"thumbnail_hobson_view_bddd844e02",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":208,
                   "height":156,
                   "size":6.56,
                   "path":null,
                   "url":"/uploads/thumbnail_hobson_view_bddd844e02.jpg"
                },
                "medium":{
                   "name":"medium_hobson-view.jpg",
                   "hash":"medium_hobson_view_bddd844e02",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":563,
                   "size":51.59,
                   "path":null,
                   "url":"/uploads/medium_hobson_view_bddd844e02.jpg"
                },
                "small":{
                   "name":"small_hobson-view.jpg",
                   "hash":"small_hobson_view_bddd844e02",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":375,
                   "size":26.59,
                   "path":null,
                   "url":"/uploads/small_hobson_view_bddd844e02.jpg"
                }
             },
             "hash":"hobson_view_bddd844e02",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":57.93,
             "url":"/uploads/hobson_view_bddd844e02.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:47:44.021Z",
             "updated_at":"2021-02-08T01:47:44.037Z"
          }
       ]
    },
    {
       "id":9,
       "Project_Name":"AMAZING HOME APARTMENT DESIGN",
       "Client":"Peter Harrinson",
       "Date":"2015-12-18",
       "Location":"136 Stancombe Rd, Flat Bush",
       "Category":"Apartment",
       "Description":null,
       "published_at":"2021-02-08T01:49:31.586Z",
       "created_at":"2021-02-08T01:49:23.396Z",
       "updated_at":"2021-02-08T01:49:31.616Z",
       "Images":[
          {
             "id":26,
             "name":"f1.jpg",
             "alternativeText":"",
             "caption":"",
             "width":604,
             "height":376,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f1.jpg",
                   "hash":"thumbnail_f1_6ed39acbb9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":245,
                   "height":153,
                   "size":10.18,
                   "path":null,
                   "url":"/uploads/thumbnail_f1_6ed39acbb9.jpg"
                },
                "small":{
                   "name":"small_f1.jpg",
                   "hash":"small_f1_6ed39acbb9",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":311,
                   "size":32.17,
                   "path":null,
                   "url":"/uploads/small_f1_6ed39acbb9.jpg"
                }
             },
             "hash":"f1_6ed39acbb9",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":42.17,
             "url":"/uploads/f1_6ed39acbb9.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:49:19.410Z",
             "updated_at":"2021-02-08T01:49:19.433Z"
          },
          {
             "id":27,
             "name":"f3.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1024,
             "height":684,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f3.jpg",
                   "hash":"thumbnail_f3_10301c4751",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":234,
                   "height":156,
                   "size":7.59,
                   "path":null,
                   "url":"/uploads/thumbnail_f3_10301c4751.jpg"
                },
                "large":{
                   "name":"large_f3.jpg",
                   "hash":"large_f3_10301c4751",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":668,
                   "size":91.8,
                   "path":null,
                   "url":"/uploads/large_f3_10301c4751.jpg"
                },
                "medium":{
                   "name":"medium_f3.jpg",
                   "hash":"medium_f3_10301c4751",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":501,
                   "size":55.89,
                   "path":null,
                   "url":"/uploads/medium_f3_10301c4751.jpg"
                },
                "small":{
                   "name":"small_f3.jpg",
                   "hash":"small_f3_10301c4751",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":334,
                   "size":27.13,
                   "path":null,
                   "url":"/uploads/small_f3_10301c4751.jpg"
                }
             },
             "hash":"f3_10301c4751",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":95.63,
             "url":"/uploads/f3_10301c4751.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:49:19.462Z",
             "updated_at":"2021-02-08T01:49:19.478Z"
          },
          {
             "id":28,
             "name":"f2.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1024,
             "height":684,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f2.jpg",
                   "hash":"thumbnail_f2_494a6f3f6e",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":234,
                   "height":156,
                   "size":9.34,
                   "path":null,
                   "url":"/uploads/thumbnail_f2_494a6f3f6e.jpg"
                },
                "large":{
                   "name":"large_f2.jpg",
                   "hash":"large_f2_494a6f3f6e",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":668,
                   "size":120.09,
                   "path":null,
                   "url":"/uploads/large_f2_494a6f3f6e.jpg"
                },
                "medium":{
                   "name":"medium_f2.jpg",
                   "hash":"medium_f2_494a6f3f6e",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":501,
                   "size":72.28,
                   "path":null,
                   "url":"/uploads/medium_f2_494a6f3f6e.jpg"
                },
                "small":{
                   "name":"small_f2.jpg",
                   "hash":"small_f2_494a6f3f6e",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":334,
                   "size":34.66,
                   "path":null,
                   "url":"/uploads/small_f2_494a6f3f6e.jpg"
                }
             },
             "hash":"f2_494a6f3f6e",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":125.42,
             "url":"/uploads/f2_494a6f3f6e.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:49:19.501Z",
             "updated_at":"2021-02-08T01:49:19.517Z"
          }
       ]
    },
    {
       "id":10,
       "Project_Name":"GREENLANE APARTMENT",
       "Client":"Mary Gordon",
       "Date":"2015-10-12",
       "Location":"Greenlane Apartment",
       "Category":"Apartment",
       "Description":null,
       "published_at":"2021-02-08T01:51:11.813Z",
       "created_at":"2021-02-08T01:51:08.840Z",
       "updated_at":"2021-02-08T01:51:11.842Z",
       "Images":[
          {
             "id":29,
             "name":"f10.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2295,
             "height":1536,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f10.jpg",
                   "hash":"thumbnail_f10_87a74f8dd4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":233,
                   "height":156,
                   "size":7.94,
                   "path":null,
                   "url":"/uploads/thumbnail_f10_87a74f8dd4.jpg"
                },
                "large":{
                   "name":"large_f10.jpg",
                   "hash":"large_f10_87a74f8dd4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":669,
                   "size":107.1,
                   "path":null,
                   "url":"/uploads/large_f10_87a74f8dd4.jpg"
                },
                "medium":{
                   "name":"medium_f10.jpg",
                   "hash":"medium_f10_87a74f8dd4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":502,
                   "size":59.37,
                   "path":null,
                   "url":"/uploads/medium_f10_87a74f8dd4.jpg"
                },
                "small":{
                   "name":"small_f10.jpg",
                   "hash":"small_f10_87a74f8dd4",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":335,
                   "size":27.99,
                   "path":null,
                   "url":"/uploads/small_f10_87a74f8dd4.jpg"
                }
             },
             "hash":"f10_87a74f8dd4",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":782.37,
             "url":"/uploads/f10_87a74f8dd4.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:51:06.251Z",
             "updated_at":"2021-02-08T01:51:06.272Z"
          },
          {
             "id":30,
             "name":"f9.jpg",
             "alternativeText":"",
             "caption":"",
             "width":2295,
             "height":1536,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f9.jpg",
                   "hash":"thumbnail_f9_cedaf8db46",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":233,
                   "height":156,
                   "size":10.78,
                   "path":null,
                   "url":"/uploads/thumbnail_f9_cedaf8db46.jpg"
                },
                "large":{
                   "name":"large_f9.jpg",
                   "hash":"large_f9_cedaf8db46",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":669,
                   "size":161.64,
                   "path":null,
                   "url":"/uploads/large_f9_cedaf8db46.jpg"
                },
                "medium":{
                   "name":"medium_f9.jpg",
                   "hash":"medium_f9_cedaf8db46",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":502,
                   "size":93.28,
                   "path":null,
                   "url":"/uploads/medium_f9_cedaf8db46.jpg"
                },
                "small":{
                   "name":"small_f9.jpg",
                   "hash":"small_f9_cedaf8db46",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":335,
                   "size":42.71,
                   "path":null,
                   "url":"/uploads/small_f9_cedaf8db46.jpg"
                }
             },
             "hash":"f9_cedaf8db46",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":825,
             "url":"/uploads/f9_cedaf8db46.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:51:06.298Z",
             "updated_at":"2021-02-08T01:51:06.316Z"
          }
       ]
    },
    {
       "id":11,
       "Project_Name":"SYLIVA PARK REST HOME",
       "Client":"Ontario Tourism Office",
       "Date":"2016-03-16",
       "Location":"Syliva Park Rest Home",
       "Category":"RetirementVillage",
       "Description":null,
       "published_at":"2021-02-08T01:52:16.393Z",
       "created_at":"2021-02-08T01:52:14.059Z",
       "updated_at":"2021-02-08T01:52:57.573Z",
       "Images":[
          {
             "id":33,
             "name":"f11.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1390,
             "height":906,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f11.jpg",
                   "hash":"thumbnail_f11_436084a56b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":239,
                   "height":156,
                   "size":8.32,
                   "path":null,
                   "url":"/uploads/thumbnail_f11_436084a56b.jpg"
                },
                "large":{
                   "name":"large_f11.jpg",
                   "hash":"large_f11_436084a56b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":652,
                   "size":92.38,
                   "path":null,
                   "url":"/uploads/large_f11_436084a56b.jpg"
                },
                "medium":{
                   "name":"medium_f11.jpg",
                   "hash":"medium_f11_436084a56b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":489,
                   "size":56.47,
                   "path":null,
                   "url":"/uploads/medium_f11_436084a56b.jpg"
                },
                "small":{
                   "name":"small_f11.jpg",
                   "hash":"small_f11_436084a56b",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":326,
                   "size":28.33,
                   "path":null,
                   "url":"/uploads/small_f11_436084a56b.jpg"
                }
             },
             "hash":"f11_436084a56b",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":161.85,
             "url":"/uploads/f11_436084a56b.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:52:08.746Z",
             "updated_at":"2021-02-08T01:52:08.763Z"
          },
          {
             "id":31,
             "name":"f12.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1079,
             "height":775,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f12.jpg",
                   "hash":"thumbnail_f12_f85b1f1896",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":217,
                   "height":156,
                   "size":7.17,
                   "path":null,
                   "url":"/uploads/thumbnail_f12_f85b1f1896.jpg"
                },
                "large":{
                   "name":"large_f12.jpg",
                   "hash":"large_f12_f85b1f1896",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":718,
                   "size":97.82,
                   "path":null,
                   "url":"/uploads/large_f12_f85b1f1896.jpg"
                },
                "medium":{
                   "name":"medium_f12.jpg",
                   "hash":"medium_f12_f85b1f1896",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":539,
                   "size":60.31,
                   "path":null,
                   "url":"/uploads/medium_f12_f85b1f1896.jpg"
                },
                "small":{
                   "name":"small_f12.jpg",
                   "hash":"small_f12_f85b1f1896",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":359,
                   "size":29.03,
                   "path":null,
                   "url":"/uploads/small_f12_f85b1f1896.jpg"
                }
             },
             "hash":"f12_f85b1f1896",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":105.99,
             "url":"/uploads/f12_f85b1f1896.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:52:08.652Z",
             "updated_at":"2021-02-08T01:52:08.678Z"
          }
       ]
    },
    {
       "id":12,
       "Project_Name":"CASTOR BAY",
       "Client":"Australia Governor",
       "Date":"2015-09-22",
       "Location":"13 Seaview Road, Castor Bay",
       "Category":"Building",
       "Description":null,
       "published_at":"2021-02-08T01:53:39.586Z",
       "created_at":"2021-02-08T01:53:36.236Z",
       "updated_at":"2021-02-08T01:53:39.614Z",
       "Images":[
          {
             "id":34,
             "name":"f14.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1279,
             "height":933,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f14.jpg",
                   "hash":"thumbnail_f14_8e8177d934",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":214,
                   "height":156,
                   "size":9.32,
                   "path":null,
                   "url":"/uploads/thumbnail_f14_8e8177d934.jpg"
                },
                "large":{
                   "name":"large_f14.jpg",
                   "hash":"large_f14_8e8177d934",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":729,
                   "size":135.61,
                   "path":null,
                   "url":"/uploads/large_f14_8e8177d934.jpg"
                },
                "medium":{
                   "name":"medium_f14.jpg",
                   "hash":"medium_f14_8e8177d934",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":547,
                   "size":82.43,
                   "path":null,
                   "url":"/uploads/medium_f14_8e8177d934.jpg"
                },
                "small":{
                   "name":"small_f14.jpg",
                   "hash":"small_f14_8e8177d934",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":365,
                   "size":39.82,
                   "path":null,
                   "url":"/uploads/small_f14_8e8177d934.jpg"
                }
             },
             "hash":"f14_8e8177d934",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":198.2,
             "url":"/uploads/f14_8e8177d934.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:52:08.786Z",
             "updated_at":"2021-02-08T01:52:08.803Z"
          },
          {
             "id":32,
             "name":"f13.jpg",
             "alternativeText":"",
             "caption":"",
             "width":1280,
             "height":845,
             "formats":{
                "thumbnail":{
                   "name":"thumbnail_f13.jpg",
                   "hash":"thumbnail_f13_9909be89b7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":236,
                   "height":156,
                   "size":9.79,
                   "path":null,
                   "url":"/uploads/thumbnail_f13_9909be89b7.jpg"
                },
                "large":{
                   "name":"large_f13.jpg",
                   "hash":"large_f13_9909be89b7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":1000,
                   "height":660,
                   "size":113.08,
                   "path":null,
                   "url":"/uploads/large_f13_9909be89b7.jpg"
                },
                "medium":{
                   "name":"medium_f13.jpg",
                   "hash":"medium_f13_9909be89b7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":750,
                   "height":495,
                   "size":68.91,
                   "path":null,
                   "url":"/uploads/medium_f13_9909be89b7.jpg"
                },
                "small":{
                   "name":"small_f13.jpg",
                   "hash":"small_f13_9909be89b7",
                   "ext":".jpg",
                   "mime":"image/jpeg",
                   "width":500,
                   "height":330,
                   "size":34.16,
                   "path":null,
                   "url":"/uploads/small_f13_9909be89b7.jpg"
                }
             },
             "hash":"f13_9909be89b7",
             "ext":".jpg",
             "mime":"image/jpeg",
             "size":161.76,
             "url":"/uploads/f13_9909be89b7.jpg",
             "previewUrl":null,
             "provider":"local",
             "provider_metadata":null,
             "created_at":"2021-02-08T01:52:08.703Z",
             "updated_at":"2021-02-08T01:52:08.723Z"
          }
       ]
    }
 ]