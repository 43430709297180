<template>
  <div
      class="page-template page-template-template-blank page-template-template-blank-php page page-id-64 elementor-default elementor-kit-452 elementor-page elementor-page-64 mobile-font">
    <div id="sidebar-menu-container" class="sidebar-menu-container">
      <div class="sidebar-menu-push">
        <div class="sidebar-menu-overlay"></div>
        <div class="sidebar-menu-inner">
          <NavBar></NavBar>

          <div id="aq-template-wrapper-119" class="aq-template-wrapper aq_row"><!-- Section Start -->
            <div id="contact-form" class="clearfix standard"
                 style="background-color:#ffffff;   padding-bottom:60px; padding-top:60px;">
              <div class="container">
                <div class="row">
                  <div id="aq-block-119-2" class="aq-block aq-block-ptf_text_block aq_span4 aq-first clearfix max-width-sm">
                    <h4 class="contact-heading text-center-sm">Get More Info</h4>
                    <div class="pa-sm-2">
                      <div class="pa-sm-1">
                        <span class="fa fa-map-marker">
                          &nbsp;&nbsp;Unit4 67-73 View Road Wairau Valley Aucland 0627
                        </span>
                      </div>
                      <div class="pa-sm-1">
                        <span class="fa fa-phone"></span>&nbsp;&nbsp;021 540 618
                      </div>
                      <div class="pa-sm-1">
                        <span class="fa fa-envelope"></span>&nbsp;&nbsp; info@huoconstruction.co.nz
                      </div>
                      <p class="pa-sm-2"><img alt="QR" src="images/QR-Code.png" width="60%"></p>
                    </div> 
                  </div>
                  <div id="aq-block-119-3" class="aq-block aq-block-ptf_text_block aq_span8 clearfix max-width-sm ma-sm-0">
                    <h4 class="contact-heading text-center-sm">Send us a Message</h4>
                    <div id="wpcf7-f115-p64-o1" class="wpcf7" dir="ltr" lang="en-US" role="form">
                      <div class="screen-reader-response">
                        <p aria-atomic="true" aria-live="polite" role="status">
                        <ul></ul>
                        </p>
                      </div>
                      <form class="wpcf7-form init max-width-sx-80 ma-sm-auto" data-status="init"
                            novalidate="novalidate">
                        <div style="display: none;">
                          <input name="_wpcf7" type="hidden" value="115"><br>
                          <input name="_wpcf7_version" type="hidden" value="5.3.2"><br>
                          <input name="_wpcf7_locale" type="hidden" value="en_US"><br>
                          <input name="_wpcf7_unit_tag" type="hidden" value="wpcf7-f115-p64-o1"><br>
                          <input name="_wpcf7_container_post" type="hidden" value="64"><br>
                          <input name="_wpcf7_posted_data_hash" type="hidden" value>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-md-4 col-sm-12">
                                <span class="wpcf7-form-control-wrap first-name">
                                  <input id="name" aria-invalid="false"
                                         aria-required="true" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                         name="first-name"
                                         placeholder="First Name" size="40"
                                         type="text"
                                         v-model="firstName">
                                         
                                  </span>
                              </div>
                              <div class="col-md-4 col-sm-12">
                                <span class="wpcf7-form-control-wrap last-name">
                                  <input id="last-name" aria-invalid="false"
                                         aria-required="true" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                         name="last-name"
                                         placeholder="Last Name"
                                         size="40"
                                         type="text"
                                         v-model="lastName">
                                </span>
                              </div>
                              <div class="col-md-4 col-sm-12">
                                <span class="wpcf7-form-control-wrap your-email">
                                  <input id="email" aria-invalid="false"
                                         aria-required="true" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                         name="your-email"
                                         placeholder="Email Address" size="40"
                                         type="email"
                                         v-model="emailAddress">
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 col-sm-12">
                                <span class="wpcf7-form-control-wrap your-message">
                                  <textarea id="message"
                                            aria-invalid="false" class="wpcf7-form-control wpcf7-textarea"
                                            cols="40"
                                            name="your-message"
                                            placeholder="Message"
                                            rows="1"
                                            v-model="message"></textarea>
                                </span>
                              </div>
                              <div class="col-md-12 col-sm-12" v-if="emailSend === ''">
                                <input id="submit-contact" class="wpcf7-form-control wpcf7-submit btn btn-accent"
                                       value="Send Message" @click="sendEmail">
                              </div>
                              <div class="col-md-12 col-sm-12" v-if="emailSend === 'sending'">
                                <input id="submit-contact" class="wpcf7-form-control wpcf7-submit btn btn-accent"
                                       value="Sending" >
                              </div>
                              <div class="col-md-12 col-sm-12" v-if="emailSend === 'sent'">
                                <p class="ml-4">Email has been sent</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p><input id="ct_checkjs_cf7_0266e33d3f546cb5436a10798e657d97" name="ct_checkjs_cf7"
                                  type="hidden" value="0"></p>
                        <div aria-hidden="true" class="wpcf7-response-output"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div><!-- Section End -->

            <!-- Section Start -->
            <div id="contact-map" class="clearfix full-width"
                 style="background-color:#ffffff;   padding-bottom:0; padding-top:0;">
              <div id="aq-block-119-6" class="aq-block aq-block-ptf_map_block aq_span12 aq-first clearfix">

                <div id="map-canvas1725832983" class="contact-map" style="height: 380px;">
                  <iframe
                      allowfullscreen=""
                      aria-hidden="false" frameborder="0" height="380" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3195.9625700524393!2d174.73433061528908!3d-36.77146537995465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d399f321aed43%3A0xa0da8f70889beac9!2s4%2F67-73%20View%20Road%2C%20Wairau%20Valley%2C%20Auckland%200627!5e0!3m2!1sen!2snz!4v1615969754326!5m2!1sen!2snz" style="border:0;"
                      width="100%"></iframe>
                </div>

              </div>
            </div><!-- Section End --></div>

          <FooterBar></FooterBar>

          <a class="go-top" href="#"><i class="fa fa-angle-up"></i></a>

        </div>

      </div>
      <nav class="sidebar-menu slide-from-left">
        <div class="nano">
          <div class="content">
            <nav class="responsive-menu">
              <ul id="menu-main-navigation-1" class>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-244"><a
                    href="index.html">Home</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-301"><a
                    href="index_13.html">Services</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-246"><a
                    href="index_2.html">Project Gallery</a></li>
                <li
                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-64 current_page_item menu-item-208">
                  <a aria-current="page" href="index_4.html">Contact</a></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-385"><a
                    href="index_15.html">About</a></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-304"><a
                    href="cn.html">中文</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import Remote from "@/remote/Remote";

export default {
  name: "Contact",
  components: {
    NavBar,
    FooterBar,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      emailAddress: "",
      message: "",
      emailSend: "",
    }
  },
  mounted() {
    // this.initialize();
    // this.loadScript()
  },
  methods: {
    sendEmail() {
      let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

      if (!emailRegex.test(this.emailAddress)) {
        alert('Please enter a valid email address');
        return;
      }

      const messagebody = {
        to: 'info@huoconstruction.co.nz',
        subject: 'Email to from huoconstruction.co.nz',
        text: `<p>First Name: ${this.firstName} <br>
              Last Name: ${this.lastName} <br>
              Email Address: ${this.emailAddress} <br>
              Message: ${this.message}</p>`
      
      }

      this.emailSend = 'sending';
      Remote.sendEmail(messagebody).then(data => {
        console.log('data', data)
        this.emailSend = 'sent';
        alert('Email sent successfully');
      })
    }
    // initialize() {
    //     var myLatlng = new google.maps.LatLng("-36.7440711",  "174.7308848");
    //     var mapOptions = {
    //         zoom : 15,
    //         center : myLatlng,
    //         scrollwheel: false,
    //         streetViewControl : true
    //     };
    //     var map = new google.maps.Map(document.getElementById("map-canvas1725832983"), mapOptions);
    //     var marker = new google.maps.Marker({
    //         position: myLatlng,
    //         map: map,
    //         title: "Contact Map",
    //         icon: null,
    //     });
    // },
    // loadScript() {
    //     var script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&' + 'callback=initialize';
    //     document.body.appendChild(script);
    // }
  },
};
</script>
<style>
.post table td a,
.page table td a,
a,
.widget_calendar #wp-calendar td a,
.blog-classic .blog-post .post-header .post-title a:hover,
.blog-isotope .blog-post .blog-title:hover .blog-title-inner,
.team-item .team-thumb .hover a:hover,
#sidebar .widget a:hover,
#projects-filter a:hover,
.service-icon,
nav.main-navigation ul a:hover,
nav.main-navigation ul .current-menu-item > a,
nav.main-navigation ul .current-menu-ancestor > a {
  color: #e6772e;
}

.sticky .post-title a {
  color: #e6772e !important;
}

.read-more:hover {
  color: #eb955c;
}

footer .footer-widgets .widget_mc4wp_form_widget form input[type="submit"],
.sidebar .widget input[type="submit"],
.sidebar .widget button,
.blog-classic .blog-post .post-date,
.progress-bar,
.btn.btn-accent,
.team-item .team-thumb .hover {
  background-color: #e6772e;
}

.sidebar .widget input[type="submit"],
.sidebar .widget button {
  border-color: #e6772e;
}

::selection {
  background: #e6772e;
}

::-moz-selection {
  background: #e6772e;
}

.title-heading {
  text-align: center;
  margin-bottom: 50px;
}

/*article.project-post .image-placeholder img {*/
/*  height: 100%;*/
/*  width: 99%;*/
/*}*/
</style>
